import React, { useState, useRef, useEffect, useMemo } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { Banner, Button, Datepicker, Label, Select, TextInput, Tooltip } from "flowbite-react";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import ImageView from "../../components/ImageView";
import utills from "../../lib/functions";
import toast from "react-hot-toast";
import { FaCheckCircle, FaPlus, FaTimesCircle, FaTruck } from "react-icons/fa";
import { GoTrash } from "react-icons/go";
import { useReactToPrint } from "react-to-print";
import { useCreateOrderMutation } from "../../redux/queries/order";
import { FcSearch } from "react-icons/fc";
import autocomplete from "autocompleter";
import { useGetUserQuery, useLazyGetUserQuery, useLazyGetUsersQuery } from "../../redux/queries/users";
import ProductSearch from "../../components/ProductSearch";
import BranchSwitcher from "../../components/BranchSwitcher";
import { useAppDispatch, useAppSelector } from "../../lib/hook";
import {
  addDiscountToInvoice,
  addToInvoice,
  chnageInvoicePrice,
  cleanRecord,
  decreaseInvoiceCartQty,
  increaseInvoiceCartQty,
  invoiceForPreview,
  removeItemFromInvoiceCart,
  resetLocalInvoice,
  setInvoiceCartQuantity,
  updateInvoiceMeta,
} from "../../redux/slices/cart";
import { v4 as uuidv4 } from "uuid";
import { useAddItemToCartMutation, useLazyGetCartItemsQuery } from "../../redux/queries/cart";
import { LuPrinter } from "react-icons/lu";
import InvoiceCart from "../../components/Documents/InvoiceCartPrint";
import VariantModal from "../../components/VariantModal";
import { useGetCompaniesQuery, useLazyGetCompaniesQuery } from "../../redux/queries/company";
import { useBlocker, useNavigate } from "react-router-dom";
import { useCallbackPrompt } from "../../lib/blocker/useCallBackPrompt";
import CustomSelect from "../../components/CustomSelect";
import Modal from "react-responsive-modal";
import { CompanyUserAdd } from "../customers/ManageCustomer";

interface MyInterface {
  label: string;
  value: string;
}

const Invoice = () => {
  const elRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handlePrint = useReactToPrint({
    content: () => elRef.current,
  });

  const { defaultBranchUUid, user } = useAppSelector((state) => state.appUserConfig);
  const { invoice, invoiceMeta, isInvoiceCurrent, duplicateItem } = useAppSelector((state) => state.cartAndDraft);
  const [getOnlineInvoice] = useLazyGetCartItemsQuery();
  const [createOnlineCart, { isLoading }] = useAddItemToCartMutation();
  const [getCompanies] = useLazyGetCompaniesQuery();
  const [getUserData] = useLazyGetUserQuery();
  const { data: loggedInUser } = useGetUserQuery(user!.uuid, { skip: !user?.uuid });

  const [createOrder] = useCreateOrderMutation();
  const [loading, setLoading] = useState<boolean>(false);
  const [qty, setQty] = useState("1");
  const [enablePrint, setEnablePrint] = useState<boolean>(false);
  const [customerFilter, setCustomerFilter] = useState({
    limit: "0,20",
    name: "",
    type: "pharmacy",
    status: 1,
  });
  const [variantModal, setVariantModal] = useState(false);
  const [branch, setBranch] = useState(defaultBranchUUid);
  const [variantItem, setVariantItem] = useState<Product>();
  const [delivery, setDelivery] = useState("0");
  const [addUser, setAddUser] = useState(false);

  const [getCustomers, { data: customersList }] = useLazyGetUsersQuery();
  const { data: companies } = useGetCompaniesQuery(customerFilter);

  const handleCustomerSelect = (data: MyInterface) => {
    dispatch(
      updateInvoiceMeta({
        delevery_fee: invoiceMeta.delevery_fee,
        customer: {
          label: "",
          value: "",
        },
        company: data,
      })
    );
    getCustomers({
      company: data.value,
      status: 1,
      limit: "0,50",
    });
  };

  const handleItemToCart = (item: Product, variant?: { id: number; name: string; price: number }) => {
    const isItemWithVariant = utills._display_variant(item.variants);
    if (isItemWithVariant && !variant) {
      setVariantItem(item);
      setVariantModal(true);
      return;
    }

    const cart_uuid = uuidv4();
    const payload: InvoiceCartWithId = {
      cart_uuid,
      uuid: item.uuid,
      price: item.selling_price,
      name: item.name,
      quantity: Number(qty),
      company: item.company.uuid,
      image: item.images_links,
      verified: item.search_by_barcode ? 1 : 0,
      base_unit: item.base_unit,
    };
    if (variant) {
      payload.variant_id = variant.id;
      payload.base_unit = variant.name;
      payload.price = variant.price;
    }
    dispatch(addToInvoice(payload));
  };

  const createInvoice = async (e: any) => {
    const payload = {
      type: 11,
      user: invoiceMeta.customer.value ?? "",
      items: invoice.map((item) => ({
        uuid: item.uuid,
        name: item.name,
        price: item.price,
        quantity: item.quantity,
        company: item.company,
        verified: item.verified,
      })),
      payment_mode: 0,
      delivery_fee: invoiceMeta.delevery_fee ?? "0",
    };
    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          const res = await createOrder(payload);
          //@ts-ignore
          if (res.error) {
            reject(res);
          } else {
            if ("data" in res && res.data.status === 200) {
              resolve(res);
              // dispatch(cleanRecord());
              setDelivery("0");
            } else reject(res);
          }
        } catch (error) {
          reject(error);
        }
      }),
      {
        loading: "Processing...",
        success: () => "Invoice created successfully",
        error: () => "Unable to crete invoice",
      },
      {
        style: {
          // minWidth: "250px",
        },
        success: {
          duration: 5000,
          icon: "🔥",
        },
      }
    );
    setEnablePrint(true);
    // handlePrint(e)
  };

  const totalPrice = () => {
    let total = 0;
    for (const item of invoice) {
      total += item.price * item.quantity;
    }
    return total;
  };

  const getInvoiceToRender = async () => {
    const res = await getOnlineInvoice({ type: "11" });
    if (res.data?.data) {
      const record = res.data.data;
      const userCompany = await getUserData(record.buyer);
      const userCompanydata = userCompany?.data?.data.company;
      const properties = {
        delevery_fee: record.delivery_fee.toString(),
        customer: {
          label: record.buyer_detail.name,
          value: record.buyer,
        },
        company: {
          label: userCompanydata?.name ?? ("" as string),
          value: userCompanydata?.uuid ?? ("" as string),
        },
      };
      const items = record.cartitems.map((item) => ({
        cart_uuid: uuidv4(),
        uuid: item.item_uuid,
        price: item.price,
        image: item.item.images,
        name: item.name,
        quantity: item.quantity,
        company: item.company,
        verified: item.verified,
        base_unit: item.item.base_unit,
      }));
      if (properties.company.value)
        getCustomers({
          limit: "0,50",
          company: properties.company.value,
          status: 1,
        });

      dispatch(
        invoiceForPreview({
          invoice: items,
          params: properties,
        })
      );
    } else if (invoiceMeta.company.value) {
      getCustomers({
        limit: "0,50",
        company: invoiceMeta.company.value,
        status: 1,
      });
    }
  };

  const saveDataToDraft = async () => {
    const payload = {
      items: invoice.map((item) => ({
        uuid: item.uuid,
        price: item.price,
        name: item.name,
        quantity: item.quantity,
        company: item.company,
        verified: item.verified,
        variant_id: item.variant_id,
        base_unit: item.base_unit,
      })),
      type: "11",
      buyer: invoiceMeta.customer.value ?? "",
      delivery_fee: Number(invoiceMeta.delevery_fee ?? "0"),
      payment_mode: "0",
    };
    const res = await createOnlineCart(payload);
    if ("data" in res) {
      const record = res.data.data;
      const userCompany = await getUserData(record.buyer);
      const userCompanydata = userCompany?.data?.data.company;

      const properties = {
        delevery_fee: record.delivery_fee.toString(),
        customer: {
          label: record.buyer_detail.name,
          value: record.buyer,
        },
        company: {
          label: userCompanydata?.name as string,
          value: userCompanydata?.uuid as string,
        },
      };

      const items = record.cartitems.map((item: ICartItems) => ({
        cart_uuid: uuidv4(),
        uuid: item.item_uuid,
        price: item.price,
        image: item.item.images,
        name: item.name,
        quantity: item.quantity,
        company: item.company,
        verified: item.verified,
        base_unit: item.item.base_unit ?? "",
      }));

      dispatch(
        invoiceForPreview({
          invoice: items,
          params: properties,
        })
      );
      dispatch(resetLocalInvoice());
      toast.success("Invoice saved to draft");
    } else {
      toast.error("Failed to save to draft please try again");
    }
  };

  useEffect(() => {
    // if (!isInvoiceCurrent) {
    getInvoiceToRender();
    // }
  }, []);

  useEffect(() => {
    if (invoice.length > 0) {
      let pickedItemCopy = structuredClone(invoice);
      const lastdata = pickedItemCopy.shift();
      if (lastdata) {
        const el = document.getElementById(lastdata.cart_uuid);
        setTimeout(() => el?.focus(), 1000);
      }
    }
  }, [invoice.length]);

  //console.log(customersList)
  const invoiceAccess = useMemo(
    () =>
      loggedInUser && !Array.isArray(loggedInUser.data.access) ? loggedInUser?.data.access.permissions.split(",").includes("inv-dsc") : undefined,
    [loggedInUser]
  );

  return (
    <div>
      <Breadcrumb title="Create Invoice" />

      <div className="mt-10">
        <Banner className="mb-5">
          <div className="flex w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 md:flex-row">
            <div className="mb-4 md:mb-0 md:mr-4">
              <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">Create Invoice For Customer.</h2>
              <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                Search through you products and create an invoice.
              </p>
            </div>
            <div className="flex flex-shrink-0 items-center">
              <LiaFileInvoiceDollarSolid className="text-5xl text-gray-500 dark:text-white" />
            </div>
          </div>
        </Banner>
      </div>

      <div className="mt-3">
        <div className="flex items-center">
          <div className="max-w-sm">
            <Label value="Invoice Date" className="mb-1" />
            <Datepicker style={{ borderRadius: 3 }} />
          </div>
        </div>

        <div>
          <div className="flex-1 my-4">
            <div className="flex gap-3">
              <div className="w-[300px]">
                <CustomSelect
                  isClearable={false}
                  defaultValue={{ ...invoiceMeta.company, disabled: false }}
                  placeholder="Select Customer..."
                  inputClasses="h-[43px] pt-1 rounded-sm"
                  options={
                    companies?.data.map((customer) => ({
                      label: customer.name,
                      value: customer.uuid,
                    })) ?? []
                  }
                  onSelect={(val) => handleCustomerSelect({ label: val.label, value: val.value })}
                  onSearch={(text) => setCustomerFilter((prev) => ({ ...prev, name: text }))}
                />
              </div>

              <div className="w-[300px]">
                <CustomSelect
                  defaultValue={{ ...invoiceMeta.customer, disabled: false }}
                  isClearable={false}
                  placeholder="Select Staff..."
                  inputClasses="h-[43px] pt-1 rounded-sm"
                  isSearchable={false}
                  options={
                    customersList?.data.map((customer) => ({
                      label: customer.name,
                      value: customer.uuid,
                    })) ?? []
                  }
                  onSelect={(val) => {
                    const userName = customersList?.data.find((item) => item.uuid === val.value);
                    if (userName) {
                      dispatch(
                        updateInvoiceMeta({
                          delevery_fee: invoiceMeta.delevery_fee,
                          company: invoiceMeta.company,
                          customer: {
                            label: userName.name,
                            value: val.value,
                          },
                        })
                      );
                    }
                  }}
                />
              </div>

              {invoiceMeta.company.value && (
                <Tooltip content="Add staff to company">
                  <button onClick={() => setAddUser(true)} className="border border-[#167490] p-3 size-[41px]">
                    <FaPlus className="text-[#167490]" />
                  </button>
                </Tooltip>
              )}

              <BranchSwitcher sizing="md" styling={{ borderRadius: 3 }} onChange={(text) => setBranch(text)} />
              <TextInput
                id="fee"
                icon={FaTruck}
                value={invoiceMeta.delevery_fee}
                min="0"
                onChange={(e) =>
                  dispatch(
                    updateInvoiceMeta({
                      delevery_fee: e.target.value,
                      customer: invoiceMeta.customer,
                      company: invoiceMeta.company,
                    })
                  )
                }
                type="number"
                step="any"
                className=""
                placeholder="Delivery fee"
                style={{ borderRadius: 2 }}
              />
            </div>

            <div className="flex flex-col gap-2 mt-2">
              <span className="dark:text-white truncate text-[11px]">Pharmacy: {invoiceMeta.company?.label}</span>
              <span className="dark:text-white truncate text-[11px]"> Customer: {invoiceMeta.customer?.label}</span>
            </div>
          </div>
        </div>

        <div className="flex items-center mt-5">
          <TextInput
            style={{ borderRadius: 3 }}
            className="w-20"
            placeholder="qty"
            type="number"
            step="any"
            disabled={loading}
            value={qty}
            onChange={(e) => setQty(e.target.value)}
          />
          <div className="flex-1 relative">
            <ProductSearch companyId={branch} onSelect={(item) => handleItemToCart(item)} error="Please Select Branch" />
          </div>
        </div>
      </div>

      <div className="mt-10">
        <div className="flex justify-between dark:text-white font-semibold text-[15px] p-2">
          <div className="flex items-center gap-2">
            <Button
              size="xs"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                if (invoice.find((item) => item.verified === 0)) {
                  toast.error("Please verify all item");
                } else if (invoice.length === 0) {
                  toast.error("Please add item to the invoice");
                } else createInvoice(e);
              }}
              className="rounded-sm"
            >
              <LiaFileInvoiceDollarSolid className="mr-2 h-5 w-5" /> Create Invoice
            </Button>

            <Button
              color="gray"
              className="rounded-sm"
              size="xs"
              onClick={() => {
                if (invoice.find((item) => item.verified === 0)) {
                  toast.error("Please verify all item");
                } else if (invoice.length === 0) {
                  toast.error("Please add item to the invoice");
                } else setEnablePrint(true);
              }}
            >
              <LuPrinter className="mr-2 h-5 w-5" />
              Print Data
            </Button>

            {isInvoiceCurrent && (
              <Button
                outline
                size="xs"
                isProcessing={isLoading}
                disabled={isLoading}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  if (invoice.length === 0) {
                    toast.error("Please add at least a product!!!");
                  } else if (!invoiceMeta.customer.value) {
                    toast.error("Please select customer!!!");
                  } else saveDataToDraft();
                }}
                className="rounded-sm"
              >
                <LiaFileInvoiceDollarSolid className="mr-2 h-5 w-5" /> Save To Draft
              </Button>
            )}

            {/* <Button
              size="xs"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                if (invoice.find((item) => item.verified === 0)) {
                  toast.error("Please verify all item");
                } else if (invoice.length === 0) {
                  toast.error("Please add item to the invoice");
                } else createInvoice(e);
              }}
              className="rounded-sm"
            >
              <LiaFileInvoiceDollarSolid className="mr-2 h-5 w-5" /> Add low stock
            </Button> */}
          </div>
          <div className="flex items-center gap-3">
            <span>Total Row: {invoice.length}</span>

            <span>Total: {utills._currency_format(totalPrice() + Number(invoiceMeta.delevery_fee ?? "0"), "NGN")}</span>
          </div>
        </div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 w-8">
                  #
                </th>
                <th scope="col" className="px-6 py-3">
                  Base Unit
                </th>
                <th scope="col" className="px-6 py-3">
                  Product
                </th>
                <th scope="col" className="px-6 py-3">
                  Qty
                </th>
                {invoiceAccess && <th scope="col" className="px-6 py-3">
                  Discount
                </th>}
                <th scope="col" className="px-6 py-3">
                  Rate
                </th>
                <th scope="col" className="px-6 py-3">
                  Price
                </th>
                <th scope="col" className="px-6 py-3">
                  Verified
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {invoice.map((item) => (
                <tr key={item.cart_uuid} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td className="p-4">
                    <ImageView className="w-8 h-8" url={utills._remove_invalid_link(item.image).length === 0 ? utills._default_img : item.image[0]} />
                  </td>
                  <td className="px-6 py-4">{item.base_unit}</td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">{item.name}</td>
                  <td className="px-6 py-4">
                    <div className="flex items-center">
                      <button
                        className="inline-flex items-center justify-center p-1 me-3 text-sm font-medium h-6 w-6 text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                        type="button"
                        onClick={() => {
                          if (item.quantity === 1) {
                            utills._play_error_sound();
                            return;
                          }
                          dispatch(decreaseInvoiceCartQty(item.cart_uuid));
                        }}
                      >
                        <span className="sr-only">Quantity button</span>
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h16" />
                        </svg>
                      </button>
                      <div>
                        <input
                          type="number"
                          step="any"
                          id={item.cart_uuid}
                          className="bg-gray-50 w-14 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="1"
                          onFocus={(e) => e.target.select()}
                          value={item.quantity}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              const element = document.getElementById("searchFields");
                              element?.focus();
                            }
                          }}
                          onChange={(e) =>
                            dispatch(
                              setInvoiceCartQuantity({
                                cart_uuid: item.cart_uuid,
                                quantity: e.target.value,
                              })
                            )
                          }
                        />
                      </div>
                      <button
                        className="inline-flex items-center justify-center h-6 w-6 p-1 ms-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                        type="button"
                        onClick={() => dispatch(increaseInvoiceCartQty(item.cart_uuid))}
                      >
                        <span className="sr-only">Quantity button</span>
                        <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                        </svg>
                      </button>
                    </div>
                  </td>
                  {invoiceAccess && <td className="px-6 py-4">
                    <input
                      type="number"
                      step="any"
                      className="bg-gray-50 w-20 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      onFocus={(e) => e.target.select()}
                      value={item.discount}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          const element = document.getElementById("searchFields");
                          element?.focus();
                        }
                      }}
                      onChange={(e) => dispatch(addDiscountToInvoice({ cart_uuid: item.cart_uuid, discount: e.target.value }))}
                    />
                  </td>}
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                    {/* {utills._currency_format(item.price, "NGN")} */}
                    <input
                      type="number"
                      step="any"
                      disabled={!invoiceAccess}
                      className="bg-gray-50 w-20 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      onFocus={(e) => e.target.select()}
                      value={item.price}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          const element = document.getElementById("searchFields");
                          element?.focus();
                        }
                      }}
                      onChange={(e) => dispatch(chnageInvoicePrice({ cart_uuid: item.cart_uuid, price: Number(e.target.value) }))}
                    />
                    </td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                    {utills._currency_format(item.price * item.quantity - Number(item.discount ?? 0), "NGN")}
                  </td>
                  <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                    {item.verified ? <FaCheckCircle className="text-green-500" /> : <FaTimesCircle className="text-red-500" />}
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center gap-3">
                      <Tooltip content="Remove Item">
                        <span
                          onClick={() => dispatch(removeItemFromInvoiceCart(item.cart_uuid))}
                          className="font-medium text-red-600 dark:text-red-500"
                        >
                          <GoTrash />
                        </span>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <InvoiceCart
        isOpen={enablePrint}
        close={() => {
          setEnablePrint(false);
          dispatch(cleanRecord());
        }}
        items={invoice}
        properties={invoiceMeta}
      />
      <VariantModal
        isOpen={variantModal}
        close={() => {
          setVariantModal(false);
        }}
        item={variantItem}
        onSelectItemVariant={(product, variant) => handleItemToCart(product, variant)}
      />

      <Modal
        showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        center
        open={addUser}
        onClose={() => setAddUser(false)}
      >
        <CompanyUserAdd
          user={companies?.data.find((x) => x.uuid === invoiceMeta.company.value)}
          close={() => {
            setAddUser(false);
            getCustomers({
              company: invoiceMeta.company.value,
              status: 1,
              limit: "0,50",
            });
          }}
          type={companies?.data.find((x) => x.uuid === invoiceMeta.company.value)?.type}
        />
      </Modal>
    </div>
  );
};

export default Invoice;
