import {
  Accordion,
  Avatar,
  Button,
  Label,
  Rating,
  Textarea,
  TextInput,
} from "flowbite-react";
import React, { useEffect, useState } from "react";
import { MdRateReview } from "react-icons/md";
import Modal from "react-responsive-modal";
import { LiaTimesSolid } from "react-icons/lia";
import { useAppSelector } from "../../lib/hook";
import {
  useGetReviewsQuery,
  useSendReviewMutation,
} from "../../redux/queries/review";
import CustomPagination from "../../components/CustomPagination";
import { useLazyGetOrdersQuery } from "../../redux/queries/order";
import CustomSelect from "../../components/CustomSelect";
import toast from "react-hot-toast";

const faqs = [
  {
    id: 1,
    question: "What is GoHealthy POS?",
    answer:
      "GoHealthy POS is the go-to-technology for pharmacies to better serve their customers and communities. With GoHealthy Pharmacy you can display your stock in real time, get orders from customers, manage their delivery and also purchase drugs wholesale from distributors and manufacturers.",
  },
  {
    id: 2,
    question: "What's the difference between quotation, invoice and order?",
    answer:
      "Quotation enables you to create a document to provide your customers with your products and their prices. An invoice is processed when a customer agrees to pay for the products and prices created. An order is  provided to show an invoice that has been paid for or barcode verified. At this point the order can be packed and picked for delivery. ",
  },
  {
    id: 3,
    question: "How can I tell when an order is coming from online?",
    answer:
      "On the left hand side of the orders page you would find different status updates. When the order type shows Online then that order is an online order. There's also a sound notification for online orders.",
  },
  {
    id: 4,
    question: "Is your money safe in your GoHealthy wallet?",
    answer:
      "The privacy and security of our customers is of utmost importance to us. As such data is stored with appropriate encryption methods and security standards.We have also partnered with Nomba Ltd who operates as a licensed Mobile Money Operator (MMO) by the Central Bank of Nigeria (CBN) and adhering to the highest regulatory standards. Furthermore, user's deposits are protected by the Nigeria Deposit Insurance Corporation (NDIC), offering the same level of insurance coverage as traditional banks.",
  },
  {
    id: 5,
    question: "When can you withdraw money from your wallet?",
    answer:
      "Wallet withdrawals are instant and are always sent in the local currency. What's an incoming order? What's an outgoing order? ",
  },
  {
    id: 6,
    question: "Can I take stock count while selling to customers?",
    answer:
      "We have created a unique stock count system that enables business owners to count their stock without closing their business. That way you can be alerted of any product discrepancy early on.",
  },
  {
    id: 7,
    question: "How do I identify my customers?",
    answer:
      "Customers on GoHealthy are classified as either of the following:Pharmacy, Manufacturer and Hospital. Pharmacy is used to identify all pharmacies, chemists, individual professionals such as nurses, doctors, pharmacies etc. Manufacturer refers to companies that produce pharmaceutical products. ",
  },
];

const FAQ = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState({
    limit: "0,9",
  });
  const { data: reviews, isLoading } = useGetReviewsQuery(filters);

  return (
    <div className="flex justify-center items-center">
      <div className="w-full 2xl:w-[1100px] my-10 relative">
        <div>
          <h2 className="text-2xl font-semibold dark:text-white text-center">
            What Our Users Say
          </h2>
          <p className="mt-3 text-lg font-medium dark:text-white text-center">
            These are what people who've used inbox has to say about the product
          </p>

          <div>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
              <CustomPagination
                setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
                isLoading={isLoading}
                data={reviews?.data}
                total={reviews?.count ?? 0}
              >
                <div className="my-10 grid grid-cols-3 gap-3">
                  {reviews?.data?.map((item) => (
                    <div
                      key={item.id}
                      className="bg-white dark:bg-gray-700 rounded-lg p-4 flex flex-col gap-3 shadow-md"
                    >
                      <div className="mb-2 flex justify-between ">
                        <div className="flex gap-2 items-center">
                          <Avatar
                            img={
                              item?.user?.avatar.length
                                ? item?.user?.avatar
                                : "https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg"
                            }
                            size="md"
                          />

                          <div className="flex flex-col">
                            <h5 className="font-semibold dark:text-white">
                              {item?.user?.name}
                            </h5>
                            <p className="text-sm font-light dark:text-white">
                              {item?.user?.type}
                            </p>
                          </div>
                        </div>
                        <div>
                          <Rating>
                            {Array.from({ length: 5 }, (_, index) => (
                              <Rating.Star key={index} filled={index < 3} />
                            ))}
                          </Rating>
                        </div>
                      </div>

                      <p className="text-sm font-medium dark:text-white">
                        {item.review.length > 200
                          ? item.review.slice(0, 200) + "..."
                          : item.review}
                      </p>
                    </div>
                  ))}
                </div>
              </CustomPagination>
            </div>
          </div>
        </div>

        <div className="mt-10">
          <h2 className="text-2xl font-semibold dark:text-white text-center">
            FAQs
          </h2>
          <p className="mt-3 text-lg font-medium dark:text-white text-center">
            You've got questions, we've got answers
          </p>

          <Accordion className="mt-10">
            {faqs.map((faq) => (
              <Accordion.Panel key={faq.id}>
                <Accordion.Title>{faq.question}</Accordion.Title>
                <Accordion.Content>
                  <p className="mb-2 text-gray-500 dark:text-gray-400">
                    {faq.answer}
                  </p>
                </Accordion.Content>
              </Accordion.Panel>
            ))}
          </Accordion>
        </div>

        <div
          onClick={() => setIsOpen(true)}
          className="bg-[#1f91b2] ml-auto h-14 w-14 rounded-[50%] shadow-lg flex justify-center items-center sticky bottom-10"
        >
          <MdRateReview size={30} />
        </div>

        <Modal
          showCloseIcon={false}
          blockScroll={false}
          classNames={{
            modalContainer: "__remove_modal_bg",
          }}
          center
          open={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <AddReview close={() => setIsOpen(false)} />
        </Modal>
      </div>
    </div>
  );
};

export default FAQ;

interface ReviewCard {
  close: () => void;
}

interface IPayload {
  limit: string;
  company: string;
  id?: string;
}

const AddReview = (props: ReviewCard) => {
  const { close } = props;
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const [fields, setFields] = useState({
    review: "",
    rating: 0,
    order_company_uuid: "",
  });

  const [getAllOrders, { data: orders }] = useLazyGetOrdersQuery();

  const getNeededOrders = async (searchId?: string) => {
    const payload: IPayload = {
      limit: "0,10",
      company: defaultBranchUUid,
    };
    if (searchId) payload.id = searchId;
    await getAllOrders({
      ...payload,
    });
  };

  useEffect(() => {
    getNeededOrders();
  }, [defaultBranchUUid]);

  const [sendReview, { isLoading }] = useSendReviewMutation();

  const onSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const payload = {
      review: fields?.review,
      rate: fields?.rating,
      order_company_uuid: "ord-itm-cpm216f5950-17ed-11ef-8dff-11ca519598f5",
    };

    const res = await sendReview(payload);
    if ("data" in res) {
      if (res?.data.status === 200 || res?.data.status === 100) {
        toast.success(res.data.message);
        close();
      } else {
        toast.error("Failed to Add Review");
      }
    }
  };

  return (
    <div className="w-[600px] rounded-t-lg">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-samibold">Add Review</span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>
      <form className="bg-white dark:bg-gray-800 p-3 pb-5">
        <div className="mt-2">
          <Label value="Search Order" />
          <CustomSelect
            onSelect={(val) =>
              setFields((prev) => ({ ...prev, order_company_uuid: val.value }))
            }
            inputClasses="h-[43px] pt-1"
            options={
              orders?.data.map((item) => ({
                label: `#${item.id.toString()}`,
                value: item.uuid,
              })) ?? []
            }
            onSearch={(text) => getNeededOrders(text)}
            placeholder="Search by id"
          />
        </div>
        <div className="mt-3">
          <div className="">
            <div className="mb-2 block">
              <Label htmlFor="review" value="Review" />
            </div>
            <Textarea
              disabled={isLoading}
              onChange={(e) =>
                setFields((prev) => ({ ...prev, review: e.target.value }))
              }
              id="review"
              placeholder="review..."
              rows={3}
            />
          </div>

          <div className="mt-5">
            <Rating>
              {Array.from({ length: 5 }, (_, index) => (
                <Rating.Star
                  key={index}
                  filled={index < fields?.rating}
                  onClick={() =>
                    setFields((prev) => ({ ...prev, rating: index + 1 }))
                  }
                />
              ))}
            </Rating>
          </div>
        </div>
        <div className="mt-3 flex items-center justify-end">
          <Button
            size="xs"
            className="rounded-sm"
            type="submit"
            disabled={
              !fields?.review.length && !fields.order_company_uuid.length
            }
            isProcessing={isLoading}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
              onSubmit(e)
            }
          >
            Add
          </Button>
        </div>
      </form>
    </div>
  );
};
