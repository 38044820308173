import { TextInput } from "flowbite-react";
import Breadcrumb from "../../components/Breadcrumb";
import { MdSearch } from "react-icons/md";
import CustomPagination from "../../components/CustomPagination";
import { useState } from "react";
import { useAppSelector } from "../../lib/hook";

const Attendance = () => {
  const { user } = useAppSelector((state) => state.appUserConfig);
  const [filters, setFilters] = useState({
    limit: "0,10",
    company: user?.company?.uuid,
    name: "",
  });
  return (
    <div>
      <Breadcrumb title="Attendance" />

      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm">
        <div className="grid grid-cols-4 gap-3">
          <div>
            <TextInput
              type="text"
              icon={MdSearch}
              placeholder="search staff..."
            />
          </div>
        </div>
      </div>

      <div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
          <CustomPagination
            setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
            isLoading={false}
            data={[]}
            total={20}
          >
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Staff
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Started Work
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Taking a break
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Off from Break
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Closed for the day
                  </th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          </CustomPagination>
        </div>
      </div>
    </div>
  );
};

export default Attendance;
