import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { Banner, Button, Label, TextInput, Tooltip } from "flowbite-react";
import ImageView from "../../components/ImageView";
import utills from "../../lib/functions";
import { GoTrash } from "react-icons/go";
import ProductSearch from "../../components/ProductSearch";
import BranchSwitcher from "../../components/BranchSwitcher";
import { useAppDispatch, useAppSelector } from "../../lib/hook";
import { BiTransferAlt } from "react-icons/bi";
import toast from "react-hot-toast";
import { useCreateOrderMutation } from "../../redux/queries/order";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { addStockTransferItem, clearStockTransferCart, decreaseStockTransferQty, increaseStockTransferQty, removeStockTransferItem, setStockTransferQuantity } from "../../redux/slices/cart";
import { RiStockLine } from "react-icons/ri";
import { useLazyGetProductsQuery } from "../../redux/queries/products";

interface MyInterface {
  label: string;
  value: string;
}

const StockTransfer = () => {
  const dispatch = useAppDispatch()
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const { stockTranferItems } = useAppSelector((state) => state.cartAndDraft);
  const [product, setProduct] = useState<Product | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [qty, setQty] = useState("1");
  const [ getLowStockProducts, { isLoading: ploading } ] = useLazyGetProductsQuery()
  const [branch, setBranch] = useState(defaultBranchUUid);
  const [toBranch, setToBranch] = useState(defaultBranchUUid);

  const [createOrder, { isLoading }] = useCreateOrderMutation();

  const addItemToCart = (
    uuid: string,
    price_in_naira: string | number,
    price_in_dollar: string | number,
    variant_id?: number,
    selectedItem?: Product
  ) => {
    const item = selectedItem ?? product;

    if (!item) return;

    let payload: InvoiceCart = {
      uuid: uuid,
      price: Number(price_in_naira),
      name: item.name,
      quantity: Number(qty),
      company: item.company.uuid,
      image: item.images_links,
      verified: item.search_by_barcode ? 1 : 0
    };
    if (variant_id) payload.variant_id = variant_id;
    // setPickedItem((prev) => [...prev, payload]);
    dispatch(addStockTransferItem(payload));
    utills._play_sound();
    setQty("1");
  };

  const createInvoice = async (e: any) => {
    if (toBranch === branch) {
      toast.error("Please transfer to a different branch!!!");
      return;
    }
    if (stockTranferItems.length === 0) {
      toast.error("Please Add Item To List");
      return;
    }
    const payload = {
      type: 12,
      // user: branch,
      items: stockTranferItems,
      payment_mode: 2,
      branch: toBranch,
    };

    toast.promise(
      new Promise(async (resolve, reject) => {
        try {
          const res = await createOrder(payload);
          //@ts-ignore
          if (res.error) {
            reject(res);
          } else {
            if ("data" in res && res.data.status === 200) {
              resolve(res);
              dispatch(clearStockTransferCart());
            } else reject(res);
          }
        } catch (error) {
          reject(error);
        }
      }),
      {
        loading: "Processing...",
        success: () => "Stock transferd successfully",
        error: () => "Unable to transfer stock",
      },
      {
        style: {
          // minWidth: "250px",
        },
        success: {
          duration: 5000,
          icon: "🔥",
        },
      }
    );
  };

  const handleLowStock = async () => {
    if(!branch){
      toast.error("Please sellect supplier")
      return
    }
    const res = await getLowStockProducts({
      supplier: branch,
      company: toBranch,
      limit: "0,60",
    });
    if(res.data?.status === 404 || res.data?.status === 400){
      toast.error("No product found")
      return
    }
    if(res.data?.data){
      for(const item of res.data.data){
        addItemToCart(
          item.uuid,
          item.selling_price,
          item.pricein_dollar,
          undefined,
          item
        );
      }
    }else toast.error("No product found")
  };
  useEffect(() => {
    if (stockTranferItems.length > 0) {
      let pickedItemCopy = structuredClone(stockTranferItems);
      const lastdata = pickedItemCopy.shift();
      if (lastdata) {
        const el = document.getElementById(lastdata.uuid);
        setTimeout(()=> el?.focus(), 1000)
      }
    }
  }, [stockTranferItems.length]);

  return (
    // <div>
    //   <Breadcrumb title="Stock Transfer" />

    //   <div className="mt-10">
    //     <Banner className="mb-5">
    //       <div className="flex w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 md:flex-row">
    //         <div className="mb-4 md:mb-0 md:mr-4">
    //           <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">
    //             Stock Transfer.
    //           </h2>
    //           <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
    //             Transfer stock to another branch.
    //           </p>
    //         </div>
    //         <div className="flex flex-shrink-0 items-center">
    //           <BiTransferAlt className="text-5xl text-gray-500 dark:text-white" />
    //         </div>
    //       </div>
    //     </Banner>
    //   </div>

    //   <div className="mt-3">
    //     <div>
    //       <div className="flex items-center gap-3 my-4">
    //         <div className="">
    //           <Label value="Transfer From" />
    //           <BranchSwitcher
    //             sizing="md"
    //             styling={{ borderRadius: 3 }}
    //             onChange={(text) => setBranch(text)}
    //           />
    //         </div>
    //         <div className="">
    //           <Label value="Transfer To" />
    //           <BranchSwitcher
    //             sizing="md"
    //             styling={{ borderRadius: 3 }}
    //             onChange={(text) => setToBranch(text)}
    //           />
    //         </div>
    //       </div>
    //     </div>

    //     <div className="flex items-center mt-5">
    //       <TextInput
    //         style={{ borderRadius: 3 }}
    //         className="w-20"
    //         placeholder="qty"
    //         type="number"
    //         step="any"
    //         disabled={loading}
    //         value={qty}
    //         onChange={(e) => setQty(e.target.value)}
    //       />
    //       <div className="flex-1 relative">
    //         <ProductSearch
    //           sizing="md"
    //           companyId={branch}
    //           onSelect={(item) => {
    //             addItemToCart(
    //               item.uuid,
    //               item.selling_price,
    //               item.pricein_dollar,
    //               undefined,
    //               item
    //             );
    //           }}
    //           error="Please Select Branch"
    //         />
    //       </div>
    //     </div>
    //   </div>

    //   <div className="mt-10">
    //     <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
    //       <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
    //         <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
    //           <tr>
    //             <th scope="col" className="px-6 py-3 w-8">
    //               #
    //             </th>
    //             <th scope="col" className="px-6 py-3">
    //               Product
    //             </th>
    //             <th scope="col" className="px-6 py-3">
    //               Qty
    //             </th>
    //             <th scope="col" className="px-6 py-3">
    //               Price
    //             </th>
    //             <th scope="col" className="px-6 py-3">
    //               Verified
    //             </th>
    //             <th scope="col" className="px-6 py-3">
    //               Action
    //             </th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //           {pickedItem.map((item) => (
    //             <tr
    //               key={item.uuid}
    //               className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
    //             >
    //               <td className="p-4">
    //                 <ImageView
    //                   className="w-8 h-8"
    //                   url={
    //                     utills._remove_invalid_link(item.image).length === 0
    //                       ? utills._default_img
    //                       : item.image[0]
    //                   }
    //                 />
    //               </td>
    //               <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
    //                 {item.name}
    //               </td>
    //               <td className="px-6 py-4">
    //                 <div className="flex items-center">
    //                   <button
    //                     className="inline-flex items-center justify-center p-1 me-3 text-sm font-medium h-6 w-6 text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
    //                     type="button"
    //                     onClick={() => {
    //                       if (item.quantity === 1) {
    //                         utills._play_error_sound();
    //                         return;
    //                       }
    //                       setPickedItem((prev) =>
    //                         prev.map((cart) => {
    //                           if (cart.uuid === item.uuid) {
    //                             return { ...cart, quantity: cart.quantity - 1 };
    //                           } else return cart;
    //                         })
    //                       );
    //                       utills._play_sound();
    //                     }}
    //                   >
    //                     <span className="sr-only">Quantity button</span>
    //                     <svg
    //                       className="w-3 h-3"
    //                       aria-hidden="true"
    //                       xmlns="http://www.w3.org/2000/svg"
    //                       fill="none"
    //                       viewBox="0 0 18 2"
    //                     >
    //                       <path
    //                         stroke="currentColor"
    //                         strokeLinecap="round"
    //                         strokeLinejoin="round"
    //                         strokeWidth="2"
    //                         d="M1 1h16"
    //                       />
    //                     </svg>
    //                   </button>
    //                   <div>
    //                     <input
    //                       type="number"
    //                       step="any"
    //                       id={item.uuid}
    //                       onKeyDown={(e) => {
    //                         if (e.key === "Enter") {
    //                           const element =
    //                             document.getElementById("searchFields");
    //                           element?.focus();
    //                         }
    //                       }}
    //                       className="bg-gray-50 w-14 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    //                       placeholder="1"
    //                       value={item.quantity}
    //                     />
    //                   </div>
    //                   <button
    //                     className="inline-flex items-center justify-center h-6 w-6 p-1 ms-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
    //                     type="button"
    //                     onClick={() => {
    //                       setPickedItem((prev) =>
    //                         prev.map((cart) => {
    //                           if (cart.uuid === item.uuid) {
    //                             return { ...cart, quantity: cart.quantity + 1 };
    //                           } else return cart;
    //                         })
    //                       );
    //                       utills._play_sound();
    //                     }}
    //                   >
    //                     <span className="sr-only">Quantity button</span>
    //                     <svg
    //                       className="w-3 h-3"
    //                       aria-hidden="true"
    //                       xmlns="http://www.w3.org/2000/svg"
    //                       fill="none"
    //                       viewBox="0 0 18 18"
    //                     >
    //                       <path
    //                         stroke="currentColor"
    //                         strokeLinecap="round"
    //                         strokeLinejoin="round"
    //                         strokeWidth="2"
    //                         d="M9 1v16M1 9h16"
    //                       />
    //                     </svg>
    //                   </button>
    //                 </div>
    //               </td>
    //               <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
    //                 {utills._currency_format(item.price, "NGN")}
    //               </td>
    //               <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
    //                 {item.verified ? (
    //                   <FaCheckCircle className="text-green-500" />
    //                 ) : (
    //                   <FaTimesCircle className="text-red-500" />
    //                 )}
    //               </td>
    //               <td className="px-6 py-4">
    //                 <div className="flex items-center gap-3">
    //                   <Tooltip content="Remove Item">
    //                     <span
    //                       onClick={() =>
    //                         setPickedItem((prev) =>
    //                           prev.filter((cart) => cart.uuid !== item.uuid)
    //                         )
    //                       }
    //                       className="font-medium text-red-600 dark:text-red-500"
    //                     >
    //                       <GoTrash />
    //                     </span>
    //                   </Tooltip>
    //                 </div>
    //               </td>
    //             </tr>
    //           ))}
    //         </tbody>
    //       </table>
    //     </div>

    //     <div className="flex-1 flex flex-col items-end">
    //       <Label value="Invoice Date" className="mb-1 opacity-0" />
    //       <Button
    //         onClick={createInvoice}
    //         isProcessing={isLoading}
    //         disabled={isLoading}
    //         className="rounded-sm"
    //       >
    //         <BiTransferAlt className="mr-2 h-5 w-5" />
    //         Transfer
    //       </Button>
    //     </div>
    //   </div>
    // </div>

    <div>
      <Breadcrumb title="Stock Transfer" />

      <div className="mt-10">
        <Banner className="mb-5">
          <div className="flex w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 md:flex-row">
            <div className="mb-4 md:mb-0 md:mr-4">
              <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">
                Stock Transfer.
              </h2>
              <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                Transfer stock to another branch.
              </p>
            </div>
            <div className="flex flex-shrink-0 items-center">
              <BiTransferAlt className="text-5xl text-gray-500 dark:text-white" />
            </div>
          </div>
        </Banner>
      </div>

      <div className="mt-3">
        <div>
          <div className="flex items-center gap-3 my-4">
            <div className="">
              <Label value="Transfer From" />
              <BranchSwitcher
                sizing="md"
                styling={{ borderRadius: 3 }}
                onChange={(text) => setBranch(text)}
              />
            </div>
            <div className="">
              <Label value="Transfer To" />
              <BranchSwitcher
                sizing="md"
                styling={{ borderRadius: 3 }}
                onChange={(text) => setToBranch(text)}
              />
            </div>
          </div>
        </div>

        <div className="flex items-center mt-5">
          <TextInput
            style={{ borderRadius: 3 }}
            className="w-20"
            placeholder="qty"
            type="number"
            step="any"
            disabled={loading}
            value={qty}
        onChange={(e) => setQty(e.target.value)}
      />
      <div className="flex-1 relative">
        <ProductSearch
          sizing="md"
          companyId={branch}
          onSelect={(item) => {
            addItemToCart(
              item.uuid,
              item.selling_price,
              item.pricein_dollar,
              undefined,
              item
            );
          }}
          error="Please Select Branch"
        />
      </div>
    </div>
  </div>

  <div className="mt-10">
    <div className="flex items-center gap-3 mb-3">
    <Button onClick={handleLowStock} disabled={ploading} isProcessing={ploading} size="xs" className="rounded-sm">
            <RiStockLine className="mr-2 size-4" /> Add low stock
          </Button>
          <Button onClick={createInvoice} disabled={isLoading} size="xs" className="rounded-sm">
        Transfer Stock
      </Button>
    </div>
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3 w-8">
              #
            </th>
            <th scope="col" className="px-6 py-3">
              Product
            </th>
            <th scope="col" className="px-6 py-3">
              Qty
            </th>
            <th scope="col" className="px-6 py-3">
              Price
            </th>
            <th scope="col" className="px-6 py-3">
              Verified
            </th>
            <th scope="col" className="px-6 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {stockTranferItems.map((item) => (
            <tr
              key={item.uuid}
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
            >
              <td className="p-4">
                <ImageView
                  className="w-8 h-8"
                  url={
                    utills._remove_invalid_link(item.image).length === 0
                      ? utills._default_img
                      : item.image[0]
                  }
                />
              </td>
              <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                {item.name}
              </td>
              <td className="px-6 py-4">
                <div className="flex items-center">
                  <button
                    className="inline-flex items-center justify-center p-1 me-3 text-sm font-medium h-6 w-6 text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                    onClick={() => dispatch(decreaseStockTransferQty(item.uuid))}
                  >
                    -
                  </button>
                  <input
                    id={item.uuid}
                    type="number"
                    value={item.quantity}
                    onChange={(e) =>
                      dispatch(
                        setStockTransferQuantity({
                          uuid: item.uuid,
                          quantity: Number(e.target.value),
                        })
                      )
                    }
                    className="bg-gray-50 w-14 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                  <button
                   className="inline-flex items-center justify-center h-6 w-6 p-1 ms-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                    onClick={() => dispatch(increaseStockTransferQty(item.uuid))}
                  >
                    +
                  </button>
                </div>
              </td>
              <td className="px-6 py-4 font-semibold text-gray-900 dark:text-white">
                ₦{item.price.toFixed(2)}
              </td>
              <td className="px-6 py-4">
                <Tooltip
                  content={item.verified ? "Verified" : "Not Verified"}
                  style="dark"
                >
                  {item.verified ? (
                    <FaCheckCircle className="text-green-500" />
                  ) : (
                    <FaTimesCircle className="text-red-500" />
                  )}
                </Tooltip>
              </td>
              <td className="px-6 py-4">
                <button
                  className="text-red-500 hover:text-red-600"
                  onClick={() => dispatch(removeStockTransferItem(item.uuid))}
                >
                  <GoTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
</div>
  );
};

export default StockTransfer;
