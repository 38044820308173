import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import {
  Alert,
  Button,
  Label,
  Select,
  Spinner,
  TextInput,
  Tooltip,
  useThemeMode,
} from "flowbite-react";
import { HiInformationCircle } from "react-icons/hi";
import ImageView from "../../components/ImageView";
import moment from "moment";
import { FaEdit, FaMap, FaPlus, FaUsers, FaWallet } from "react-icons/fa";
import { MdPedalBike } from "react-icons/md";
import { LuPackage } from "react-icons/lu";
import Modal from "react-responsive-modal";
import { LiaTimesSolid } from "react-icons/lia";
import CustomerUsers from "./CustomerUsers";
import {
  useGetCompanyQuery,
  useUpdateCompanyMutation,
} from "../../redux/queries/company";
import CustomersOrder from "./CustomersOrder";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { GiMedicines } from "react-icons/gi";
import CustomerDrugs from "./CustomerDrugs";
import CustomerWallet from "./CustomerWallet";
import {
  useCreateUserMutation,
  useUpdateUserMutation,
} from "../../redux/queries/users";
import { BiSolidPlaneTakeOff } from "react-icons/bi";
import toast from "react-hot-toast";
import AddressSearch from "../../components/AddressSearch";
import {
  useAssignSellingGroupsMutation,
  useGetSellingGroupsQuery,
} from "../../redux/queries/sellingGroup";
import { useAppSelector } from "../../lib/hook";
import { FaPeopleGroup } from "react-icons/fa6";
import { useGetCountriesQuery } from "../../redux/queries/settings";
import {
  useGetCitiesQuery,
  useGetStatesQuery,
} from "../../redux/queries/location";
import PhoneInput from "react-phone-input-2";
import { lowerCase } from "lodash";
import { GrTransaction } from "react-icons/gr";
import CustomerTransactions from "./CustomerTransactions";
import { GiCardDiscard } from "react-icons/gi";
import CustomerDiscards from "./CustomerDiscards";
import CustomLoan from "./CustomLoan";

const cards = [
  { title: "Edit", icon: FaEdit, route: "edit", modal: true },
  { title: "Orders", icon: LuPackage, route: "orders", modal: false },
  { title: "Users", icon: FaUsers, route: "users", modal: false },
  { title: "Riders", icon: MdPedalBike, route: "riders", modal: false },
  { title: "Address", icon: FaMap, route: "address", modal: true },
  { title: "Drugs", icon: GiMedicines, route: "drugs", modal: false },
  {
    title: "Assign Selling Group",
    icon: FaPeopleGroup,
    route: "assign_group",
    modal: true,
  },
  { title: "Wallet", icon: FaWallet, route: "wallet", modal: false },
  {
    title: "Transaction",
    icon: GrTransaction,
    route: "transaction",
    modal: false,
  },
  { title: "Discards", icon: GiCardDiscard, route: "discards", modal: false },
  {
    title: "Loan Repayment",
    icon: BiSolidPlaneTakeOff,
    route: "loan",
    modal: false,
  },
];
const ManageCustomer = () => {
  const navigate = useNavigate();
  const { id, type } = useParams();
  const { data: customer, isLoading } = useGetCompanyQuery(id ?? "");
  const [page, setPage] = useState("index");
  const [screen, setScreen] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [addUser, setAddUser] = useState(false);

  if (isLoading) {
    return (
      <div className="flex justify-center pt-7">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <Breadcrumb title={`Customer - ${customer?.data?.name}`} />

      {customer?.status !== 200 ? (
        <div>
          <Alert color="failure" icon={HiInformationCircle}>
            <span className="font-medium">Error!</span> Customer not found.
          </Alert>
        </div>
      ) : (
        <div className="flex items-start gap-4 mt-5">
          <div className="w-[340px] bg-white dark:bg-gray-800 rounded-t-lg">
            <div className="bg-gray-200 dark:bg-gray-700 h-12 flex justify-center items-center rounded-t-lg">
              <span className="text-[14px] font-semibold text-[#167490]">
                {customer?.data?.name}
              </span>
            </div>
            <div className="p-3">
              <div className="flex justify-center py-4">
                <ImageView
                  url={customer?.data?.avatar_link}
                  className="w-28 h-28"
                />
              </div>
              <div>
                <div className="relative overflow-x-auto shadow-sm">
                  <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <tbody>
                      <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <td className="px-5 py-3">
                          <b>Code</b>
                        </td>
                        <td className="px-5 py-3">
                          {customer?.data?.code ?? "-"}
                        </td>
                      </tr>
                      <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <td className="px-5 py-3">
                          <b>Wallet</b>
                        </td>
                        <td className="px-5 py-3">
                          {customer?.data?.wallet ?? "-"}
                        </td>
                      </tr>
                      <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <td className="px-5 py-3">
                          <b>Loan</b>
                        </td>
                        <td className="px-5 py-3">
                          {customer?.data?.loan ?? "-"}
                        </td>
                      </tr>
                      <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <td className="px-5 py-3">
                          <b>Name</b>
                        </td>
                        <td className="px-5 py-3">
                          {customer?.data?.name ?? "-"}
                        </td>
                      </tr>
                      <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <td className="px-5 py-3">
                          <b>Email</b>
                        </td>
                        <td className="px-5 py-3 truncate ... max-w-8">
                          <Tooltip content={customer.data.email ?? "-"}>
                            {customer?.data?.email ?? "-"}
                          </Tooltip>
                        </td>
                      </tr>
                      <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <td className="px-5 py-3">
                          <b>Phone No.</b>
                        </td>
                        <td className="px-5 py-3">
                          {customer?.data?.phone ?? "-"}
                        </td>
                      </tr>
                      <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <td className="px-5 py-3">
                          <b>Country</b>
                        </td>
                        <td className="px-5 py-3">
                          {customer?.data?.country?.name ?? "-"}
                        </td>
                      </tr>
                      <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <td className="px-5 py-3">
                          <b>Status</b>
                        </td>
                        <td className="px-5 py-3">
                          {customer?.data?.status === 1 ? "Active" : "Inactive"}
                        </td>
                      </tr>
                      <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <td className="px-5 py-3">
                          <b>Date</b>
                        </td>
                        <td className="px-5 py-3">
                          {moment(customer?.data?.crdate).format("LLL")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div className="bg-white flex justify-between items-center dark:bg-gray-800 p-2 rounded-t-lg">
              <Button
                size="xs"
                className="rounded-sm"
                color="gray"
                onClick={() => {
                  if (page === "index")
                    navigate(
                      customer.data.type === "manufacturer"
                        ? "/manufacturer"
                        : customer.data.type === "hospital"
                        ? "/hospital"
                        : "/customers"
                    );
                  else setPage("index");
                }}
              >
                Go Back
              </Button>
              <Button
                size="xs"
                className="rounded-sm"
                onClick={() => setAddUser(true)}
              >
                <FaPlus className="mr-2" />
                Add User
              </Button>
            </div>

            {page === "index" && (
              <div className="mt-5 grid grid-cols-4 gap-5 px-3">
                {cards.map((card) => {
                  if (
                    card.route === "assign_group" &&
                    customer.data.type === "manufacturer"
                  )
                    return null;
                  return (
                    <div
                      onClick={() => {
                        if (card.modal) {
                          setScreen(card.route);
                          setIsOpen(true);
                        } else setPage(card.route);
                      }}
                      key={card.route}
                      className="cursor-pointer bg-white dark:bg-gray-800 hover:scale-[1.1] transition-all w-32 h-32 flex justify-center items-center rounded-lg"
                    >
                      <div className="flex flex-col justify-center items-center">
                        <card.icon className="text-3xl text-[#167490]" />
                        <span className="text-xs text-gray-700 font-semibold dark:text-gray-200">
                          {card.title}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {page === "users" && (
              <CustomerUsers
                back={() => setPage("index")}
                user={customer?.data}
                type={customer?.data?.type}
              />
            )}
            {page === "riders" && (
              <CustomerUsers
                back={() => setPage("index")}
                user={customer?.data}
                type="rider"
              />
            )}
            {page === "orders" && (
              <CustomersOrder
                back={() => setPage("index")}
                user={customer?.data}
                type="rider"
              />
            )}
            {page === "drugs" && (
              <CustomerDrugs
                back={() => setPage("index")}
                user={customer?.data}
                type="rider"
              />
            )}
            {page === "wallet" && (
              <CustomerWallet staffId={customer?.data?.uuid} />
            )}
            {page === "transaction" && (
              <CustomerTransactions companyId={customer?.data?.uuid} />
            )}
            {page === "discards" && (
              <CustomerDiscards
                back={() => setPage("index")}
                user={customer?.data}
              />
            )}
            {page === "loan" && <CustomLoan companyId={customer?.data?.uuid} />}
          </div>
        </div>
      )}

      <Modal
        showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        center
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        {screen === "address" && (
          <Address user={customer?.data} close={() => setIsOpen(false)} />
        )}
        {screen === "edit" && (
          <EditUser user={customer?.data} close={() => setIsOpen(false)} />
        )}
        {screen === "assign_group" && (
          <AssignSellingGroup
            user={customer?.data}
            close={() => setIsOpen(false)}
          />
        )}
      </Modal>

      <Modal
        showCloseIcon={false}
        blockScroll={false}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        center
        open={addUser}
        onClose={() => setAddUser(false)}
      >
        <CompanyUserAdd
          user={customer?.data}
          close={() => setAddUser(false)}
          type={type}
        />
      </Modal>
    </div>
  );
};

export default ManageCustomer;

interface IFormInputAddUser {
  name: string;
  email?: string;
  phone: string;
  status: string;
  password: string;
}

const schemaAddUser = yup.object().shape({
  name: yup.string().required("Name is a required field"),
  email: yup.string(),
  phone: yup.string().required("Phone number is a required field"),
  status: yup.string().required("Status is a required field"),
  password: yup.string().required("password is a required field"),
});

export const CompanyUserAdd = (props: UserCard) => {
  const { user, close, type } = props;
  const [createUser, { isLoading: isCreatingLoading }] =
    useCreateUserMutation();
  const [updateUser, { isLoading: loading }] = useUpdateUserMutation();
  const { mode } = useThemeMode();

  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputAddUser>({ resolver: yupResolver(schemaAddUser) });

  useEffect(() => {
    if (user) {
      setValue("name", "");
      setValue("email", "");
      setValue("phone", "");
      setValue("status", "");
      setValue("password", "");
    }
  }, []);

  const onSubmit = async (data: IFormInputAddUser) => {
    const payload = {
      name: data.name,
      email: data.email,
      phone: Number(data.phone),
      status: Number(data.status),
      type: type,
      password: data.password,
      country_id: user?.country?.id,
      company: user?.uuid,
    };

    const res = (await createUser(payload)) as any;
    if (res?.data?.status === 200) {
      if ("data" in res) {
        const result = await updateUser({
          uuid: res?.data?.data.uuid,
          type: res?.data?.data.type,
          company: user?.uuid,
        });
        if ("data" in result) {
          if (result.data.status === 200) {
            toast.success("User Added");
            close();
          } else toast.error("Unable to add user");
        } else toast.error("Unable to add user");
      } else toast.error("Unable to add user");
    } else toast.error(res.data.data.join(","));
  };

  return (
    <div className="w-[500px] rounded-t-lg">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-samibold">
          Add User To Company
        </span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>

      <form className="bg-white p-3 pb-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-3 mt-2">
          <div className="">
            <span className="text-xs dark:text-gray-800 font-semibold mb-2">
              Full Name
            </span>
            <TextInput
              disabled={loading ?? isCreatingLoading}
              sizing="md"
              type="text"
              placeholder="Enter Your Full Name"
              {...register("name")}
              color={errors?.name ? "failure" : "gray"}
              helperText={
                errors.name && (
                  <span className="font-medium text-[10px]">
                    {" "}
                    {errors.name?.message}!
                  </span>
                )
              }
            />
          </div>

          <div className="">
            <span className="text-xs dark:text-gray-800 font-semibold mb-2">
              Email Address
            </span>
            <TextInput
              disabled={loading ?? isCreatingLoading}
              sizing="md"
              type="email"
              placeholder="Enter Email Address"
              {...register("email")}
            />
          </div>

          <div className="">
            <span className="text-xs dark:text-gray-800 font-semibold mb-2">
              Phone Number
            </span>

            <Controller
              name="phone"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div>
                  <PhoneInput
                    {...field}
                    country={lowerCase(user?.country?.code)}
                    disabled={loading ?? isCreatingLoading}
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    buttonStyle={{
                      backgroundColor: errors?.phone
                        ? "rgb(254 242 242)"
                        : mode === "light"
                        ? "rgb(249 250 251)"
                        : "rgb(75 85 99)",
                      border: `1px solid ${
                        errors?.phone
                          ? "rgb(239 68 68)"
                          : mode === "light"
                          ? "rgb(209 213 219)"
                          : "rgb(75 85 99)"
                      }`,
                    }}
                    inputStyle={{
                      width: "auto",
                      height: "42px",
                      paddingRight: "5px",
                      backgroundColor: errors?.phone
                        ? "rgb(254 242 242)"
                        : mode === "light"
                        ? "rgb(249 250 251)"
                        : "rgb(75 85 99)",
                      border: `1px solid ${
                        errors?.phone
                          ? "rgb(239 68 68)"
                          : mode === "light"
                          ? "rgb(209 213 219)"
                          : "rgb(75 85 99)"
                      }`,
                      color: mode === "light" ? "black" : "white",
                    }}
                    dropdownStyle={{
                      position: "absolute",
                      top: -20,
                      left: 0,
                    }}
                  />
                  {errors.phone && (
                    <span className="font-medium text-[10px] text-red-500">
                      {" "}
                      {errors.phone?.message}!
                    </span>
                  )}
                </div>
              )}
            />
          </div>

          <div>
            <span className="text-xs dark:text-gray-800 font-semibold mb-2">
              Status
            </span>
            <Select
              disabled={loading ?? isCreatingLoading}
              sizing="md"
              {...register("status")}
              color={errors?.status ? "failure" : "gray"}
              helperText={
                errors.status && (
                  <span className="font-medium text-[10px]">
                    {" "}
                    {errors.status?.message}
                  </span>
                )
              }
            >
              <option value="" disabled hidden>
                Select a status
              </option>
              <option value="1">Active</option>
              <option value="0">InActive</option>
            </Select>
          </div>

          <div>
            <span className="text-xs dark:text-gray-800 font-semibold mb-2">
              Password
            </span>
            <TextInput
              type="password"
              disabled={loading ?? isCreatingLoading}
              placeholder="Enter a Password"
              autoComplete="off"
              {...register("password")}
              color={errors?.password ? "failure" : "gray"}
              helperText={
                errors.password && (
                  <span className="font-medium text-[10px]">
                    {" "}
                    {errors.password?.message}!
                  </span>
                )
              }
            />
          </div>
        </div>

        <div className="mt-3 flex items-center justify-end">
          <Button
            disabled={isCreatingLoading ?? loading}
            isProcessing={isCreatingLoading ?? loading}
            size="md"
            type="submit"
            className="rounded-sm"
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

interface UserCard {
  user: Branch | undefined;
  close: () => void;
  type?: string;
}
const Address = (props: UserCard) => {
  const { user, close } = props;
  const [address, setAddress] = useState({
    name: "",
    lat: 0,
    lng: 0,
  });
  const [addressDetails, setAddressDetails] = useState(user?.address2);
  const [updateCompany, { isLoading }] = useUpdateCompanyMutation();

  const updateAddress = async () => {
    const result = await updateCompany({
      uuid: user?.uuid,
      address,
      address2: addressDetails,
    });
    if ("data" in result) {
      if (result.data.status === 200) {
        toast.success("Address Updated");
        close();
      } else toast.error(result.data.message ?? "Unable to save address");
    } else toast.error("Unable to save address");
  };

  return (
    <div className="w-[500px] rounded-t-lg">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-samibold">Address</span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>
      <div className="bg-white dark:bg-gray-700 p-3 pb-5">
        <div className="">
          <div className="mb-2 block">
            <Label
              htmlFor="address"
              value={
                user?.address.name
                  ? `Current Address: ${user.address.name}`
                  : "Address"
              }
            />
          </div>

          <AddressSearch sizing="md" onSelect={(val) => setAddress(val)} />
        </div>
        <div className=" mt-3">
          <div className="mb-2 block">
            <Label htmlFor="address" value={"Address Details"} />
          </div>

          <TextInput
            id="address-details"
            value={addressDetails}
            type="text"
            className="flex-1"
            sizing="md"
            onChange={(e) => setAddressDetails(e.target.value)}
          />
        </div>
        <div className="mt-3 flex items-center justify-end">
          <Button
            disabled={isLoading}
            isProcessing={isLoading}
            size="xs"
            className="rounded-sm"
            onClick={updateAddress}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

interface IFormInput {
  name: string;
  email?: string;
  phone: string;
  status: string;
  countryName?: string;
  city?: string;
  whatsapp?: string;
  state_id?: string;
}

const schema = yup.object().shape({
  name: yup.string().required("Name is a required field"),
  email: yup.string(),
  phone: yup.string().required("Phone number is a required field"),
  status: yup.string().required("Status is a required field"),
  whatsapp: yup.string(),
  city: yup.string(),
  countryName: yup.string(),
  state_id: yup.string(),
});
const EditUser = (props: UserCard) => {
  const { close, user } = props;
  const [updateCompany, { isLoading }] = useUpdateCompanyMutation();
  const { data: countries } = useGetCountriesQuery();
  const { mode } = useThemeMode();
  const [countryArr, setCountryArr] = useState<any>([]);
  const [countryName, setCountryName] = useState<any>(user?.country?.name);
  const [stateId, setStateId] = useState<any>(user?.state_id);

  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (user) {
      setValue("name", user.name);
      setValue("email", user.email);
      setValue("phone", user.phone);
      setValue("status", user.status.toString());
      setValue("whatsapp", user.whatsapp === null ? "" : user.whatsapp);
      setValue("countryName", user.country.name);
      setValue("state_id", user.state_id ? user.state_id.toString() : "");
      setValue("city", user?.city?.name);
    }
  }, [user, setValue]);

  useEffect(() => {
    const getCountry = countries?.data?.filter(
      (country) => country?.name === countryName
    );
    setCountryArr(getCountry);
  }, [countries?.data, countryName]);

  const { data: states } = useGetStatesQuery({
    country_id: countryArr ? countryArr[0]?.id : null,
  });

  const { data: cities } = useGetCitiesQuery({
    country_id: countryArr ? countryArr[0]?.id : null,
    state_id: stateId,
  });

  const intialStatesArr = states?.data?.filter(
    (item) => item.id === user?.state_id
  );

  const stateName = intialStatesArr?.length ? intialStatesArr[0]?.name : "";

  const onSubmit = async (data: IFormInput) => {
    const { countryName, state_id, city, ...rest } = data;
    const getCountry = countries?.data?.filter(
      (country) => country?.name === data?.countryName
    );
    const getCity = cities?.data?.filter((cityinn) => cityinn?.name === city);

    const payload = {
      ...rest,
      uuid: user?.uuid,
      state_id: Number(state_id),
      city_id: getCity ? getCity[0]?.id : null,
      country: getCountry ? getCountry[0]?.id : null,
    };
    const res = await updateCompany(payload);
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success("Record updted");
      } else toast.error("Unable to update");
    } else toast.error("Unable to update");
  };

  return (
    <div className="w-[600px] rounded-t-lg">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-samibold">
          Edit Customer Info
        </span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>
      <form
        className="bg-white dark:bg-gray-800 p-3 pb-5"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex gap-3">
          <div>
            <ImageView url={user?.avatar_link} className="w-20 h-20" />
          </div>
          <div className="flex-1">
            <div
              className="grid grid-cols-2 gap-3"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <Label value="Name" />
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={isLoading}
                  placeholder="Name"
                  {...register("name")}
                  color={errors?.name ? "failure" : "gray"}
                  helperText={
                    errors.name && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.name?.message}!
                      </span>
                    )
                  }
                />
              </div>
              <div>
                <Label value="Email" />
                <TextInput
                  sizing="sm"
                  type="text"
                  disabled={isLoading}
                  placeholder="Email"
                  {...register("email")}
                  color={errors?.email ? "failure" : "gray"}
                  helperText={
                    errors.email && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.email?.message}!
                      </span>
                    )
                  }
                />
              </div>
              <div>
                <Label value="Phone" />
                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <div>
                      <PhoneInput
                        {...field}
                        country={lowerCase(user?.country?.code)}
                        disabled={isLoading}
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                        }}
                        buttonStyle={{
                          backgroundColor: errors?.phone
                            ? "rgb(254 242 242)"
                            : mode === "light"
                            ? "rgb(249 250 251)"
                            : "rgb(75 85 99)",
                          border: `1px solid ${
                            errors?.phone
                              ? "rgb(239 68 68)"
                              : mode === "light"
                              ? "rgb(209 213 219)"
                              : "rgb(75 85 99)"
                          }`,
                        }}
                        inputStyle={{
                          width: "auto",
                          paddingRight: "5px",
                          backgroundColor: errors?.phone
                            ? "rgb(254 242 242)"
                            : mode === "light"
                            ? "rgb(249 250 251)"
                            : "rgb(75 85 99)",
                          border: `1px solid ${
                            errors?.phone
                              ? "rgb(239 68 68)"
                              : mode === "light"
                              ? "rgb(209 213 219)"
                              : "rgb(75 85 99)"
                          }`,
                          color: mode === "light" ? "black" : "white",
                        }}
                        dropdownStyle={{
                          position: "absolute",
                          top: -20,
                          left: 0,
                        }}
                      />
                      {errors.phone && (
                        <span className="font-medium text-[10px] text-red-500">
                          {" "}
                          {errors.phone?.message}!
                        </span>
                      )}
                    </div>
                  )}
                />
              </div>
              <div>
                <Label value="Status" />
                <Select
                  sizing="sm"
                  {...register("status")}
                  disabled={isLoading}
                  color={errors?.status ? "failure" : "gray"}
                  helperText={
                    errors.status && (
                      <span className="font-medium text-[10px]">
                        {" "}
                        {errors.status?.message}!
                      </span>
                    )
                  }
                >
                  <option value={1}>Active</option>
                  <option value={0}>InActive</option>
                </Select>
              </div>

              <div>
                <Label value="WhatsApp Number" />
                <Controller
                  name="whatsapp"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <div>
                      <PhoneInput
                        {...field}
                        country={lowerCase(user?.country?.code)}
                        disabled={isLoading}
                        inputProps={{
                          name: "whatsapp",
                          required: true,
                          autoFocus: true,
                        }}
                        buttonStyle={{
                          backgroundColor: errors?.phone
                            ? "rgb(254 242 242)"
                            : mode === "light"
                            ? "rgb(249 250 251)"
                            : "rgb(75 85 99)",
                          border: `1px solid ${
                            errors?.phone
                              ? "rgb(239 68 68)"
                              : mode === "light"
                              ? "rgb(209 213 219)"
                              : "rgb(75 85 99)"
                          }`,
                        }}
                        inputStyle={{
                          width: "auto",
                          paddingRight: "5px",
                          backgroundColor: errors?.phone
                            ? "rgb(254 242 242)"
                            : mode === "light"
                            ? "rgb(249 250 251)"
                            : "rgb(75 85 99)",
                          border: `1px solid ${
                            errors?.phone
                              ? "rgb(239 68 68)"
                              : mode === "light"
                              ? "rgb(209 213 219)"
                              : "rgb(75 85 99)"
                          }`,
                          color: mode === "light" ? "black" : "white",
                        }}
                        dropdownStyle={{
                          position: "absolute",
                          bottom: -20,
                          left: 0,
                        }}
                      />
                      {errors.whatsapp && (
                        <span className="font-medium text-[10px] text-red-500">
                          {" "}
                          {errors.whatsapp?.message}!
                        </span>
                      )}
                    </div>
                  )}
                />
              </div>

              <div>
                <Label value="Country" />
                <Select
                  sizing="sm"
                  {...register("countryName", {
                    onChange: (e) => {
                      setCountryName(e.target.value);
                    },
                  })}
                  disabled={isLoading}
                >
                  <option value={user?.country?.name} hidden disabled>
                    {user?.country?.name}
                  </option>

                  {countries?.data?.map((country) => (
                    <option key={country?.id} value={country?.name}>
                      {country.name}
                    </option>
                  ))}
                </Select>
              </div>

              <div>
                <Label value="State" />
                <Select
                  sizing="sm"
                  {...register("state_id", {
                    onChange: (e) => {
                      setStateId(e.target.value);
                    },
                  })}
                  disabled={isLoading}
                >
                  <option value={stateName} hidden disabled>
                    {stateName}
                  </option>

                  {states?.data?.map((state) => (
                    <option key={state?.id} value={state?.id}>
                      {state.name}
                    </option>
                  ))}
                </Select>
              </div>
              <div>
                <Label value="City" />
                <Select sizing="sm" {...register("city")} disabled={isLoading}>
                  <option value={user?.city?.name} hidden disabled>
                    {user?.city?.name}
                  </option>

                  {cities?.data?.map((cityinn: any) => (
                    <option key={cityinn?.id} value={cityinn?.name}>
                      {cityinn.name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 flex items-center justify-end">
          <Button
            size="xs"
            className="rounded-sm"
            type="submit"
            isProcessing={isLoading}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

const AssignSellingGroup = (props: UserCard) => {
  const { close, user } = props;
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const [filters, setFilters] = useState({
    limit: "0,40",
    company: defaultBranchUUid,
    name: "",
  });
  const [assignSellingGroups, { isLoading }] = useAssignSellingGroupsMutation();
  const [value, setValue] = useState("");

  const { data: sellingGroups } = useGetSellingGroupsQuery({ ...filters });

  const submit = async () => {
    const res = await assignSellingGroups({
      selling_group: value,
      company: user?.uuid as string,
    });
    if ("data" in res) {
      if (res.data.status === 200) {
        toast.success("Record Saved");
        close();
      } else
        toast.error(res.data.data.join(",") ?? "Unable to assign pharmarcy");
    } else toast.error("Unable to assign pharmarcy");
  };

  return (
    <div className="w-[400px] rounded-t-lg">
      <div className="p-3 rounded-t-lg bg-[#167490] flex justify-between items-center">
        <span className="text-white text-sm font-samibold">
          Assign Selling Group
        </span>
        <span className="text-white cursor-pointer" onClick={close}>
          <LiaTimesSolid />
        </span>
      </div>

      <div className="bg-white dark:bg-gray-800 p-3 pb-5">
        <div>
          <Label value="selling groups" />
          <Select
            disabled={isLoading}
            sizing="sm"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          >
            <option value="">Select selling group</option>
            {sellingGroups?.data.map((item) => (
              <option key={item.uuid} value={item.uuid}>
                {item.name}
              </option>
            ))}
          </Select>
        </div>
        <div className="flex justify-end mt-5">
          <Button
            isProcessing={isLoading}
            disabled={isLoading}
            onClick={submit}
            size="xs"
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
