import { TbEdit, TbPackages } from "react-icons/tb";
import { IoListCircleSharp, IoStatsChart } from "react-icons/io5";
import {
  MdAssignmentAdd,
  MdRequestQuote,
  MdPointOfSale,
  MdLocalGroceryStore,
} from "react-icons/md";
import { ImPriceTags } from "react-icons/im";
import { PiUsersFourFill, PiUsersFill, PiGearFineBold } from "react-icons/pi";
import { FaFileInvoice } from "react-icons/fa";
import { BsBlockquoteLeft, BsBookshelf } from "react-icons/bs";
import { BiSolidBarcode, BiSolidSortAlt, BiTransferAlt } from "react-icons/bi";
import { IoMdGitBranch } from "react-icons/io";
import { RiStockFill } from "react-icons/ri";
import { SiAuth0, SiGoogletagmanager } from "react-icons/si";
import { LuHistory } from "react-icons/lu";
import { GiMeepleGroup } from "react-icons/gi";
import { FaChartPie } from "react-icons/fa6";
import { BsBank } from "react-icons/bs";
import { FaWallet } from "react-icons/fa6";
import { MdPayments } from "react-icons/md";
import { CiMoneyCheck1 } from "react-icons/ci";
import { TiMessages } from "react-icons/ti";
import { FaQuestionCircle } from "react-icons/fa";
import { GrTransaction } from "react-icons/gr";
import { SiFuturelearn } from "react-icons/si";
import { GrOverview } from "react-icons/gr";
import { GiExpense } from "react-icons/gi";
import { MdLocalHospital } from "react-icons/md";
import { BiSolidPlaneTakeOff } from "react-icons/bi";
import { AiOutlineStock } from "react-icons/ai";
import { TbTruckReturn } from "react-icons/tb";
import { FaPeopleGroup } from "react-icons/fa6";

const sideBarMenu = [
  {
    title: "Dashboard",
    route: "/dashboard",
    icon: IoStatsChart,
    isNew: false,
    code: "dash",
  },
  {
    title: "Product",
    route: "",
    icon: TbPackages,
    isNew: false,
    code: "prod",
    children: [
      {
        title: "Product List",
        route: "/products",
        icon: IoListCircleSharp,
        code: "prod-list",
      },
      {
        title: "Add Product",
        route: "/add-product",
        icon: MdAssignmentAdd,
        code: "prod-add",
      },
      {
        title: "Product Barcode",
        route: "/barcode",
        icon: BiSolidBarcode,
        code: "prod-brc",
      },
    ],
  },

  {
    title: "Sales",
    route: "",
    icon: MdRequestQuote,
    isNew: false,
    code: "iord",
    children: [
      {
        title: "Orders",
        route: "/orders",
        icon: ImPriceTags,
        code: "iord-ord",
      },
      {
        title: "Order Returns",
        route: "/returnwithoutid",
        icon: TbTruckReturn,
        code: "rtn-id-out",
      },
      {
        title: "Add Invoice",
        route: "/invoice",
        icon: FaFileInvoice,
        code: "iord-inv",
      },
      {
        title: "Add Quotation",
        route: "/quotations",
        icon: BsBlockquoteLeft,
        code: "iord-quo",
      },
      {
        title: "POS",
        route: "/pos",
        icon: MdPointOfSale,
        code: "iord-pos",
      },
    ],
  },
  {
    title: "Purchases",
    route: "",
    icon: RiStockFill,
    isNew: false,
    code: "ogord",
    children: [
      {
        title: "Purchase History",
        route: "/purchase-history",
        icon: LuHistory,
        code: "ogord-phi",
      },
      {
        title: "Purchase Returns",
        route: "/purchase-return",
        icon: TbTruckReturn,
        code: "pur-rtn",
      },
      {
        title: "Add Purchase",
        route: "/add-purchase",
        icon: MdLocalGroceryStore,
        code: "ogord-aph",
      },
      {
        title: "Stock Transfer",
        route: "/stock-history",
        icon: LuHistory,
        code: "ogord-str",
      },
      {
        title: "Add Stock Transfer",
        route: "/stock-transfer",
        icon: BiTransferAlt,
        code: "ogord-astr",
      },
    ],
  },
  {
    title: "Stock Count",
    route: "",
    icon: BiSolidSortAlt,
    code: "stc",
    children: [
      {
        title: "Stock Count",
        route: "/stock-count",
        icon: BiSolidSortAlt,
        code: "stc-cnt",
      },
      {
        title: "Shelfs",
        route: "/shelfs",
        icon: BsBookshelf,
        code: "stc-shlf",
        isNew: false,
      },
    ],
  },
  {
    title: "Stock Management",
    route: "",
    icon: SiGoogletagmanager,
    code: "stm",
    children: [
      {
        title: "Product Management",
        route: "/product-management",
        icon: TbEdit,
        isNew: false,
        code: "stm-pmng",
      },
      {
        title: "Product Statistics",
        route: "/product-statistics",
        icon: FaChartPie,
        isNew: false,
        code: "statis",
      },
      {
        title: "Stock Movement",
        route: "/stock-movement",
        icon: AiOutlineStock,
        isNew: false,
        code: "stock-ma",
      },
    ],
  },
  {
    title: "Customers",
    route: "",
    icon: PiUsersFourFill,
    code: "cst",
    children: [
      {
        title: "Pharmacy",
        route: "/customers",
        icon: PiUsersFourFill,
        isNew: false,
        code: "cst-pha",
      },
      {
        title: "Manufacturer",
        route: "/manufacturer",
        icon: PiUsersFourFill,
        isNew: false,
        code: "cst-man",
      },
      {
        title: "Hospital",
        route: "/hospital",
        icon: MdLocalHospital,
        isNew: false,
        code: "cst-hos",
      },
      {
        title: "Selling Groups",
        route: "/selling-groups",
        icon: GiMeepleGroup,
        code: "cst-slg",
      },
    ],
  },
  // {
  //   title: "Returns/Refund",
  //   route: "/returns-refunds",
  //   icon: RiRefund2Line,
  //   isNew: false,
  //   code: "rtn",
  // },
  {
    title: "Manage Team",
    route: "",
    icon: PiUsersFill,
    code: "empm",
    children: [
      {
        title: "Employees",
        route: "/employees",
        icon: PiUsersFill,
        isNew: false,
        code: "empm-emp",
      },
      {
        title: "Attendance",
        route: "/attendance",
        icon: FaPeopleGroup,
        isNew: false,
        code: "empm-att",
      },
      {
        title: "Permissions",
        route: "/permissions",
        icon: SiAuth0,
        isNew: false,
        code: "empm-per",
      },
      {
        title: "Payout",
        route: "/payout",
        icon: MdPayments,
        isNew: false,
        code: "pay-out",
      },
    ],
  },
  {
    title: "Accounting",
    route: "",
    icon: BsBank,
    isNew: false,
    code: "acc",
    children: [
      {
        title: "Transactions",
        route: "/transactions",
        icon: GrTransaction,
        code: "trns",
      },
      {
        title: "Wallet",
        route: "/wallet",
        icon: FaWallet,
        code: "wlt",
      },
      {
        title: "Cheques",
        route: "/cheque",
        icon: CiMoneyCheck1,
        code: "chq",
      },
      {
        title: "Income Statement",
        route: "/income",
        icon: SiFuturelearn,
        code: "inc",
      },
      {
        title: "Customer Overview",
        route: "/overview",
        icon: GrOverview,
        code: "cus-over",
      },
      {
        title: "Expenses",
        route: "/expenses",
        icon: GiExpense,
        code: "exp",
      },
      {
        title: "Loans",
        route: "/loans",
        icon: BiSolidPlaneTakeOff,
        code: "ln",
      },
    ],
  },
  {
    title: "Branches",
    route: "/branches",
    icon: IoMdGitBranch,
    isNew: false,
    code: "brn",
  },
  {
    title: "Settings",
    route: "/settings",
    icon: PiGearFineBold,
    isNew: false,
    code: "stn",
  },

  {
    title: "Ask Support",
    route: "/contact",
    icon: TiMessages,
    isNew: false,
    code: "ctu",
  },

  {
    title: "FAQ",
    route: "/faq",
    icon: FaQuestionCircle,
    isNew: false,
    code: "faq",
  },
];

export { sideBarMenu };
