import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PurchaseItem } from "../../screens/purchase";

interface InvoiceMeta {
  delevery_fee: string;
  customer: {
    value: string;
    label: string;
  };
  company: {
    value: string;
    label: string;
  };
}
interface InitialState {
  cart: Cart[] | [];
  cartDraft: Cart[] | [];
  duplicateItem: boolean;
  isInvoiceCurrent: boolean;
  invoice: InvoiceCartWithId[];
  invoiceMeta: InvoiceMeta;
  purchaseCart: {
    items: PurchaseItem[];
    invoiceId: string;
  };
  stockTranferItems: InvoiceCart[];
  quotationCart: InvoiceCart[];
}
const initialState: InitialState = {
  cart: [],
  cartDraft: [],
  duplicateItem: false,
  invoice: [],
  isInvoiceCurrent: false,
  invoiceMeta: {
    delevery_fee: "0",
    customer: {
      value: "",
      label: "",
    },
    company: {
      value: "",
      label: "",
    },
  },
  purchaseCart: {
    invoiceId: "",
    items: [],
  },
  stockTranferItems: [],
  quotationCart: [],
};
const cartSlice = createSlice({
  name: "CART AND DRAFT",
  initialState,
  reducers: {
    invoiceForPreview: (
      state,
      action: PayloadAction<{
        invoice: InvoiceCartWithId[];
        params: InvoiceMeta;
      }>
    ) => {
      state.invoice = action.payload.invoice;
      state.invoiceMeta = action.payload.params;
    },
    addToCart: (state, action: PayloadAction<Cart>) => {
      if (state.duplicateItem) {
        state.cart = [action.payload, ...state.cart];
        return;
      }
      const itemExistIndex = state.cart.find(
        (item) => item.itemId === action.payload.itemId
      );
      if (itemExistIndex) {
        state.cart.map((item) => {
          if (item.itemId === action.payload.itemId) {
            return { ...item, quantity: item.quantity++ };
          } else return item;
        });
      } else state.cart = [action.payload, ...state.cart];
    },
    changeQty: (state, action: PayloadAction<{ id: string; qty: number }>) => {
      state.cart = state.cart.map((item) => {
        if (item.itemId === action.payload.id) {
          return { ...item, quantity: action.payload.qty };
        } else return item;
      });
    },
    deleteItem: (state, action: PayloadAction<string>) => {
      state.cart = state.cart.filter((item) => item.itemId !== action.payload);
    },
    clearCart: (state) => {
      state.cart = [];
    },
    updateCart: (
      state,
      action: PayloadAction<{ id: string; variant_id: number; price: number }>
    ) => {
      state.cart = state.cart.map((item) => {
        if (item.itemId === action.payload.id) {
          return {
            ...item,
            variant_id: action.payload.variant_id,
            price_in_naira: action.payload.price,
          };
        } else return item;
      });
    },
    setDuplicateCart: (state) => {
      state.duplicateItem = !state.duplicateItem;
    },
    addToInvoice: (state, action: PayloadAction<InvoiceCartWithId>) => {
      state.invoice = [action.payload, ...state.invoice];
      state.isInvoiceCurrent = true;
    },
    chnageInvoicePrice: (state, action: PayloadAction<{ cart_uuid: string; price: number }>) => {
      state.invoice = state.invoice.map((item) => {
        if (item.cart_uuid === action.payload.cart_uuid) {
          return { ...item, price: action.payload.price };
        } else return item;
      });
      state.isInvoiceCurrent = true;
    },
    increaseInvoiceCartQty: (state, action: PayloadAction<string>) => {
      state.invoice = state.invoice.map((item) => {
        if (item.cart_uuid === action.payload) {
          return { ...item, quantity: item.quantity + 1 };
        } else return item;
      });
      state.isInvoiceCurrent = true;
    },
    addDiscountToInvoice: (state, action: PayloadAction<{ cart_uuid: string; discount: string }>) => {
      state.invoice = state.invoice.map((item) => {
        if (item.cart_uuid === action.payload.cart_uuid) {
          return { ...item, discount: action.payload.discount };
        } else return item;
      });
      state.isInvoiceCurrent = true;
    },
    decreaseInvoiceCartQty: (state, action: PayloadAction<string>) => {
      state.invoice = state.invoice.map((item) => {
        if (item.cart_uuid === action.payload) {
          return { ...item, quantity: item.quantity - 1 };
        } else return item;
      });
      state.isInvoiceCurrent = true;
    },
    setInvoiceCartQuantity: (
      state,
      action: PayloadAction<{ cart_uuid: string; quantity: string }>
    ) => {
      state.invoice = state.invoice.map((item) => {
        if (item.cart_uuid === action.payload.cart_uuid) {
          return { ...item, quantity: Number(action.payload.quantity) };
        } else return item;
      });
      state.isInvoiceCurrent = true;
    },
    removeItemFromInvoiceCart: (state, action: PayloadAction<string>) => {
      state.invoice = state.invoice.filter(
        (item) => item.cart_uuid !== action.payload
      );
      state.isInvoiceCurrent = true;
    },
    updateInvoiceMeta: (state, action: PayloadAction<InvoiceMeta>) => {
      state.invoiceMeta = action.payload;
      state.isInvoiceCurrent = true;
    },
    resetLocalInvoice: (state) => {
      state.isInvoiceCurrent = false;
    },
    cleanRecord: (state) => {
      state.invoice = [];
      state.invoiceMeta = {
        delevery_fee: "0",
        customer: {
          label: "",
          value: "",
        },
        company: {
          label: "",
          value: "",
        },
      };
      state.isInvoiceCurrent = false;
    },
    addToPurchase: (state, action: PayloadAction<PurchaseItem>) => {
      state.purchaseCart.items = [...state.purchaseCart.items, action.payload];
    },
    increasePurchaseCartQty: (
      state,
      action: PayloadAction<{ uuid: string; amount: number }>
    ) => {
      state.purchaseCart.items = state.purchaseCart.items.map((item) => {
        if (item.uuid === action.payload.uuid) {
          return { ...item, quantity: item.quantity + action.payload.amount };
        } else return item;
      });
    },
    decreasePurchaseCartQty: (
      state,
      action: PayloadAction<{ uuid: string; amount: number }>
    ) => {
      state.purchaseCart.items = state.purchaseCart.items.map((item) => {
        if (item.uuid === action.payload.uuid) {
          return { ...item, quantity: item.quantity - action.payload.amount };
        } else return item;
      });
    },
    setPurchaseCartQuantity: (
      state,
      action: PayloadAction<{ uuid: string; quantity: number }>
    ) => {
      const { uuid, quantity } = action.payload;
      const item = state.purchaseCart.items.find((item) => item.uuid === uuid);
      if (item) {
        item.quantity = quantity;
      }
    },
    updatePurchaseCartItem: (
      state,
      action: PayloadAction<{
        uuid: string;
        price?: number;
        selling_price?: string;
        batch_no?: string;
        expiring_date?: string;
      }>
    ) => {
      const { uuid, price, selling_price, batch_no, expiring_date } =
        action.payload;
      const item = state.purchaseCart.items.find((item) => item.uuid === uuid);
      if (item) {
        if (price !== undefined) item.price = price;
        if (selling_price !== undefined) item.selling_price = selling_price;
        if (batch_no !== undefined) item.batch_no = batch_no;
        if (expiring_date !== undefined) item.expiring_date = expiring_date;
      }
    },
    removePurchaseCartItem: (state, action: PayloadAction<string>) => {
      const uuid = action.payload;
      state.purchaseCart.items = state.purchaseCart.items.filter(
        (item) => item.uuid !== uuid
      );
    },
    emptyPurchaseCart: (state) => {
      state.purchaseCart = { items: [], invoiceId: "" };
    },
    addStockTransferItem: (state, action: PayloadAction<InvoiceCart>) => {
      state.stockTranferItems.push(action.payload);
    },
    removeStockTransferItem: (state, action: PayloadAction<string>) => {
      state.stockTranferItems = state.stockTranferItems.filter(
        (item) => item.uuid !== action.payload
      );
    },
    increaseStockTransferQty: (state, action: PayloadAction<string>) => {
      const item = state.stockTranferItems.find(
        (item) => item.uuid === action.payload
      );
      if (item) {
        item.quantity += 1;
      }
    },
    decreaseStockTransferQty: (state, action: PayloadAction<string>) => {
      const item = state.stockTranferItems.find(
        (item) => item.uuid === action.payload
      );
      if (item && item.quantity > 1) {
        item.quantity -= 1;
      }
    },
    setStockTransferQuantity: (
      state,
      action: PayloadAction<{ uuid: string; quantity: number }>
    ) => {
      const item = state.stockTranferItems.find(
        (item) => item.uuid === action.payload.uuid
      );
      if (item) {
        item.quantity = action.payload.quantity;
      }
    },
    clearStockTransferCart: (state) => {
      state.stockTranferItems = [];
    },


    addQuotationItem: (state, action: PayloadAction<InvoiceCart>) => {
      state.quotationCart.push(action.payload);
    },
    removeQuotationItem: (state, action: PayloadAction<string>) => {
      state.quotationCart = state.quotationCart.filter(
        (item) => item.uuid !== action.payload
      );
    },
    increaseQuotationQty: (state, action: PayloadAction<string>) => {
      const item = state.quotationCart.find(
        (item) => item.uuid === action.payload
      );
      if (item) {
        item.quantity += 1;
      }
    },
    decreaseQuotationQty: (state, action: PayloadAction<string>) => {
      const item = state.quotationCart.find(
        (item) => item.uuid === action.payload
      );
      if (item && item.quantity > 1) {
        item.quantity -= 1;
      }
    },
    setQuotationQuantity: (
      state,
      action: PayloadAction<{ uuid: string; quantity: number }>
    ) => {
      const item = state.quotationCart.find(
        (item) => item.uuid === action.payload.uuid
      );
      if (item) {
        item.quantity = action.payload.quantity;
      }
    },
    clearQuotationCart: (state) => {
      state.quotationCart = [];
    },
  },
});

export const {
  emptyPurchaseCart,
  updatePurchaseCartItem,
  removePurchaseCartItem,
  setPurchaseCartQuantity,
  addToPurchase,
  increasePurchaseCartQty,
  decreasePurchaseCartQty,
  addToCart,
  changeQty,
  deleteItem,
  clearCart,
  updateCart,
  setDuplicateCart,
  addToInvoice,
  chnageInvoicePrice,
  decreaseInvoiceCartQty,
  increaseInvoiceCartQty,
  setInvoiceCartQuantity,
  removeItemFromInvoiceCart,
  updateInvoiceMeta,
  resetLocalInvoice,
  invoiceForPreview,
  addDiscountToInvoice,
  cleanRecord,
  addStockTransferItem,
  removeStockTransferItem,
  increaseStockTransferQty,
  decreaseStockTransferQty,
  setStockTransferQuantity,
  clearStockTransferCart,

  addQuotationItem,
  removeQuotationItem,
  increaseQuotationQty,
  decreaseQuotationQty,
  setQuotationQuantity,
  clearQuotationCart,
} = cartSlice.actions;
export default cartSlice.reducer;
