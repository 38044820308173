import { Button, Label, Radio, Textarea, TextInput } from "flowbite-react";
import { useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import utills from "../../lib/functions";
import { useAppSelector } from "../../lib/hook";
import { FaCheckCircle } from "react-icons/fa";
import { CgCheckO } from "react-icons/cg";
import toast from "react-hot-toast";
import Modal from "react-responsive-modal";
import { LiaTimesSolid } from "react-icons/lia";
import { useGetSettingsQuery } from "../../redux/queries/settings";
import {
  useGetBanksQuery,
  useInitializePaymentMutation,
  useInitiateTransferMutation,
  useLazyGetBankVerifiedQuery,
  useSendPaymentMutation,
} from "../../redux/queries/Payments";
import CustomSelect from "../../components/CustomSelect";

interface IPurchasePayment {
  close: () => void;
  orderUuid: string;
  purchase?: IPurchaseHistory;
}
const PurchasePayment = (props: IPurchasePayment) => {
  const { close, orderUuid, purchase } = props;
  const { cart } = useAppSelector((state) => state.cartAndDraft);
  const { data: settings } = useGetSettingsQuery();
  const [bankData, setBankData] = useState<
    { account_no: string; account_name: string; bank_id: number } | undefined
  >();
  // const [sendPayment, ] = useSendPaymentMutation();
  const [ initializePayment ] = useInitializePaymentMutation()
  const [ initiateTransfer ] = useInitiateTransferMutation()
  const [isLoading, setIsLoading] = useState(false)

  const [paymentMode, setPaymentMode] = useState(0);
  // const [customerBalance, setCustomerBalance] = useState(0);
  const [amountPaid, setAmountPaid] = useState("");
  const [description, setDiscription] = useState("")
  const [bankVerification, setBankVerification] = useState(false);

  // const calCustomerBal = (val: string) => {
  //   setAmountPaid(val);
  //   const { totalPriceInNaira } = utills._total_item_in_cart(cart);
  //   const bal = Number(val) - totalPriceInNaira;
  //   setCustomerBalance(bal);
  // };

  const handlePayment = async (val: string) => {
    if (val === "2") {
      setBankVerification(true);
      // console.log(res)
    }
    setPaymentMode(Number(val));
    setBankData(undefined);  
  };

  const setBankRecord = (
    account_no: string,
    account_name: string,
    bank_id: number
  ) => {
    setBankData({ account_name, account_no, bank_id });
  };

  const submit = async () => {
    if (!amountPaid) {
      toast.error("Please enter amount to pay");
      return;
    }

    const payload = {
      type: "add_purchase",
      company: purchase?.company.uuid,
      payment_mode: paymentMode,
      amount: Number(amountPaid),
      token: purchase?.uuid,
      bank: bankData,
      description,
    };

    setIsLoading(true);

    const res = await initializePayment(payload);
    if ("data" in res && res.data.status === 200) {
      toast.success(res.data.message);
      if (paymentMode === 2) {
        const result = await initiateTransfer({ uuid: res.data.data.uuid });
        //@ts-ignore
        if (result.data.status !== 200) {
          //@ts-ignore
          toast.error(result.data.message);
        } else {
          //@ts-ignore
          toast.success(result.data.message);
          close();
        }
      } else {
        close();
      }
    } else {
      //@ts-ignore
      const errorMessage = res.data.message || "An error occurred";
      //@ts-ignore
      const additionalData = Array.isArray(res.data.data)
        //@ts-ignore
        ? res.data.data.join(", ")
        : "";
      toast.error(`${errorMessage} ${additionalData}`);
    }
    setIsLoading(false);
  };



  if (!purchase) return <div></div>;

  return (
    <div className="h-full flex flex-col bg-white dark:bg-gray-800 min-w-[450px]">
      <div className="flex justify-between items-center px-3 py-3 border-b border-gray-300">
        <h4 className="font-semibold dark:text-white">Payment</h4>
        <span className="cursor-pointer" onClick={close}>
          <AiOutlineCloseCircle className="dark:text-white" />
        </span>
      </div>

      <div className="flex item center flex-1">
        <div className="flex-1 flex flex-col justify-between">
          <div className="px-3 flex flex-col justify-between flex-1">
            <div>
              <fieldset className="flex max-w-md flex-col gap-4 mt-2">
                <Label value="Payment Method" />
                {settings?.data.outgoing_payment.map((payment: any, index) => (
                  <div className="flex items-center gap-2" key={payment}>
                    <Radio
                      id={payment}
                      defaultChecked={index === 0}
                      name="payment"
                      value={index}
                      onChange={(e) => handlePayment(e.target.value)}
                    />
                    <Label
                      className="flex items-center capitalize truncate ... max-w-[400px]"
                      htmlFor={payment}
                    >
                      {/* <BsCash className="mr-2" />  */}
                      {payment.replaceAll("_", " ")}
                      {index === 2 && bankData && (
                        <div className="flex flex-1 items-center">
                          <FaCheckCircle className="text-green-400 ml-2" /> 
                          <span className="text-[11px] ml-2">{`(${bankData.account_name})`}</span>
                        </div>
                      )}
                    </Label>
                  </div>
                ))}
              </fieldset>
              <div className="border-t mt-3 border-gray-300 dark:border-gray-600">
                <Label value="Summary" className="ml-2" />
                <div className="text-[13px] dark:text-gray-300 mt-3">
                  <div className="flex justify-between items-center bg-gray-200 dark:bg-gray-700 p-2 mb-1 rounded-sm">
                    <span>Tax:</span>
                    <span className="font-semibold">
                      {utills._currency_format(0, "NGN")}
                    </span>
                  </div>
                  <div className="flex justify-between items-center bg-gray-200 dark:bg-gray-700 p-2 mb-1 rounded-sm">
                    <span>Total Paid:</span>
                    <span className="font-semibold">
                      {utills._currency_format(
                        purchase?.amount_paid ?? 0,
                        "NGN"
                      )}
                    </span>
                  </div>

                  <div className="flex justify-between items-center bg-gray-200 dark:bg-gray-700 p-2 mb-1 rounded-sm">
                    <span>Total Amount:</span>
                    <span className="font-semibold">
                      {utills._currency_format(
                        purchase?.total_cost ?? 0,
                        "NGN"
                      )}
                    </span>
                  </div>

                  <div className="flex justify-between items-center bg-gray-200 dark:bg-gray-700 p-2 mb-1 rounded-sm">
                    <span>Balance Due:</span>
                    <span className="font-semibold">
                      {utills._currency_format(
                        purchase!.total_cost - purchase!.amount_paid ?? 0,
                        "NGN"
                      )}
                    </span>
                  </div>
                </div>

                <div className="mt-3">
                  <div className="mb-2 block">
                    <Label htmlFor="amount" value="Enter Amount To Pay" />
                  </div>
                  <TextInput
                    sizing="sm"
                    id="amount"
                    // onChange={(e) => calCustomerBal(e.target.value)}
                    onChange={(e) => setAmountPaid(e.target.value)}
                    value={amountPaid}
                    type="number"
                    step="any"
                    placeholder="Amount Paid By Customer"
                    shadow
                    style={{ borderRadius: 2 }}
                  />
                </div>
                <div className="flex justify-end items-center pt-1 dark:text-gray-300 text-[13px]">
                  <span className="mr-2">Balance:</span>
                  <span
                    className={`font-semibold ${
                      Number(amountPaid ?? '0') < (purchase.total_cost - purchase.amount_paid) &&
                      "text-red-400"
                    }`}
                  >
                    {utills._currency_format(
                      (purchase.total_cost - purchase.amount_paid) - Number(amountPaid ?? '0'),
                      "NGN"
                    )}
                  </span>
                </div>

                <div>
                  <Label value="Description" />
                  <Textarea value={description} onChange={(e)=> setDiscription(e.target.value)} />
                </div>
              </div>
            </div>
          </div>

          <div className="px-3 mb-2">
            <Button
              isProcessing={isLoading}
              disabled={
                purchase!.amount_paid >= purchase!.total_cost ?? isLoading
              }
              className="rounded-sm w-full"
              size="xs"
              onClick={submit}
            >
              <CgCheckO className="mr-2 h-5 w-5" />
              Pay Now
            </Button>
          </div>
        </div>
      </div>

      <Modal
        open={bankVerification}
        onClose={() => setBankVerification(false)}
        classNames={{
          modalContainer: "__remove_modal_bg",
        }}
        showCloseIcon={false}
      >
        <div className="w-[400px]">
          <div className="bg-[#167490] flex items-center justify-between h-10 px-3">
            <span className="text-white font-semibold text-sm">
              Verify Bank
            </span>
            <span
              className="text-white cursor-pointer"
              onClick={() => setBankVerification(false)}
            >
              <LiaTimesSolid />
            </span>
          </div>
          <BankVerification
            close={() => setBankVerification(false)}
            setBankRecord={setBankRecord}
          />
        </div>
      </Modal>
    </div>
  );
};

export default PurchasePayment;

const BankVerification = ({
  close,
  setBankRecord,
}: {
  close: () => void;
  setBankRecord: (
    account_no: string,
    account_name: string,
    bank_id: number
  ) => void;
}) => {
  const { data: banks } = useGetBanksQuery({});
  const [getBankVerify] = useLazyGetBankVerifiedQuery();
  const [isLoading, setIsLoading] = useState(false)
  const [bankNumber, setBankNumber] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [bankCode, setBankCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankData, setBankData] = useState<
    { account_no: string; account_name: string; bank_id: number } | undefined
  >();

  const verify = async () => {
    if(!bankNumber || bankNumber.length < 10 ){
      toast.error("Please enter a valid account numbe")
      return
    }
    setIsLoading(true)
    const res = await getBankVerify({
      bank_code: bankCode,
      number: bankNumber,
    });
    setIsLoading(false)
    if (res.data.status === 200) {
      
      const bankDetails = res.data.data as {
        account_number: string;
        account_name: string;
        bank_id: number;
      };
      setBankName(bankDetails.account_name);
      setBankData(
       {account_no: bankDetails.account_number,
        account_name: bankDetails.account_name,
        bank_id: bankDetails.bank_id}
      );
      toast.success("Bank verified successfully");
      // close()
    } else {
      toast.error(res.data.message);
    }
  };

  return (
    <div className="p-3 bg-white dark:bg-gray-800 flex flex-col justify-between h-[500px]">
      <div className="flex flex-col gap-3">
        <div className="max-w-sm">
          <Label value="Select Bank" className="mb-1" />
          <CustomSelect
            isClearable={false}
            onSelect={(val) => {
              setBankName("")
              setBankNumber("")
              setBankCode(val.value)
            }}
            inputClasses="h-[43px] pt-1"
            options={
              banks?.data
                .filter((x) =>
                  x.name.toLowerCase().includes(searchKey.toLowerCase())
                )
                .map((item) => ({ label: item.name, value: item.code })) ?? []
            }
            onSearch={(text) => setSearchKey(text)}
            placeholder="Banks"
          />
        </div>

        {bankCode && (
          <div className="max-w-sm">
            <Label value="Account Number" className="mb-1" />
            <TextInput
              type="text"
              value={bankNumber}
              onChange={(e) => setBankNumber(e.target.value)}
            />
          </div>
        )}

        {bankName && (
          <div className="text-[14px] font-semibold mt-4">
            <span>Name on account: </span>
            <span className="text-green-400">{bankName}</span>
          </div>
        )}
      </div>
      {!bankName ? (
        <Button
          disabled={isLoading}
          isProcessing={isLoading}
          onClick={verify}
          className="w-full rounded-md mt-5"
        >
          Verify
        </Button>
      ) : (
        <Button
          disabled={isLoading}
          isProcessing={isLoading}
          onClick={()=> {
            setBankRecord(
              bankData!.account_no,
              bankData!.account_name,
              bankData!.bank_id
            );
            close()
          }}
          className="w-full rounded-md mt-5"
        >
          Confirm
        </Button>
      )}
    </div>
  );
};
