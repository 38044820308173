import Breadcrumb from "../../components/Breadcrumb";
import { Badge, Banner, Label } from "flowbite-react";
import { LuHistory } from "react-icons/lu";
import {
  useGetStockListQuery,
} from "../../redux/queries/order";
import ImageView from "../../components/ImageView";
import utills from "../../lib/functions";
import { useGetSettingsQuery } from "../../redux/queries/settings";
import CustomDropdown from "../../components/CustomDropdown";
import { CiCircleMore, CiViewList } from "react-icons/ci";
import { FaRegEdit } from "react-icons/fa";
import { useState } from "react";
import ViewItems from "./ViewItems";
import { useNavigate } from "react-router-dom";
import BranchSwitcher from "../../components/BranchSwitcher";
import { useAppSelector } from "../../lib/hook";
import CustomPagination from "../../components/CustomPagination";

const StockTransferHistory = () => {
  const navigate = useNavigate()
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const [filters, setFilters] = useState({
    limit: "0,10",
    company: defaultBranchUUid,
    buyer: ""
  });
  const [isOpen, setIsOpen] = useState(false);
  const [orderUuid, setOrderUuid] = useState("")
  const { data: stockHistory, isLoading } = useGetStockListQuery({
    ...filters,
  });
  const { data: settings } = useGetSettingsQuery();

  const order_status = settings?.data.order_status ?? [];

  const handleViewItems = (uuid: string) => {
    setOrderUuid(uuid);
    setIsOpen(true)
  };

  return (
    <div>
      <Breadcrumb title="Stock Transfer History" />

      <div className="mt-10">
        <Banner className="mb-5">
          <div className="flex w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 md:flex-row">
            <div className="mb-4 md:mb-0 md:mr-4">
              <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">
                Stock Transfer History.
              </h2>
              <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                Stock transfer history records.
              </p>
            </div>
            <div className="flex flex-shrink-0 items-center">
              <LuHistory className="text-5xl text-gray-500 dark:text-white" />
            </div>
          </div>
        </Banner>
      </div>

      <div className="flex items-center gap-4 p-4 bg-white rounded-md mb-4 dark:bg-gray-700">
        <button
          onClick={()=> setFilters((prev)=> ({ ...prev, company: defaultBranchUUid, buyer: "" }))}

          className={`${"rounded-lg border-2 h-10 w-32 text-sm font-semibold border-[#167490]"} ${
            filters.company
              ? "bg-[#167490] text-white"
              : "text-[#167490] hover:bg-[#167490]/10"
          }`}
        >
          Outgoing
        </button>
        <button
         onClick={()=> setFilters((prev)=> ({ ...prev, buyer: defaultBranchUUid, company: "" }))}
          className={`${"rounded-lg border-2 h-10 w-32 text-sm font-semibold border-[#167490]"} ${
            filters.buyer
              ? "bg-[#167490] text-white"
              : "text-[#167490] hover:bg-[#167490]/10"
          }`}
        >
          Incoming
        </button>
      </div>



      <div className="grid grid-cols-4 bg-white px-3 py-5 rounded-sm dark:bg-gray-800">
      <div>
        <Label value="Branch" className="mb-2" />
            <BranchSwitcher
              sizing="md"
              defaultCompany={filters.company}
              onChange={(value) =>
                setFilters((prev) => ({ ...prev, company: value }))
              }
            />
          </div>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <CustomPagination
          setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
          isLoading={isLoading}
          data={stockHistory?.data}
          total={stockHistory?.count ?? 0}
        >
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3 w-9">
                  #
                </th>
                <th scope="col" className="px-6 py-3">
                  Id
                </th>
                <th scope="col" className="px-6 py-3">
                  Total Price
                </th>
                <th scope="col" className="px-6 py-3">
                  Total Item
                </th>
                <th scope="col" className="px-6 py-3">
                  Order Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Type
                </th>
                {/* <th scope="col" className="px-6 py-3">
                Base Unit
              </th>
              <th scope="col" className="px-6 py-3">
                Min/Max
              </th>
              <th scope="col" className="px-6 py-3">
                Price&#40;&#8358;&#41;
              </th> */}
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {stockHistory?.data.map((stock) => (
                <tr
                  key={stock.uuid}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td
                    onClick={(e) => e.stopPropagation()}
                    className="px-6 py-4"
                  >
                    <ImageView url={utills._default_img} className="w-8 h-8" />
                  </td>
                  <td className="px-6 py-4">#{stock.id}</td>
                  <td className="px-6 py-4">
                    {utills._currency_format(stock.total_price ?? 0, "NGN")}
                  </td>
                  <td className="px-6 py-4">{stock.items}</td>
                  <td className="px-6 py-4">
                    <Badge className="w-max" color="indigo">
                      {utills._get_status_value(stock.status, order_status)}
                    </Badge>
                  </td>
                  <td className="px-6 py-4">{stock.order.order_type}</td>
                  <td className="px-6 py-4">
                    <CustomDropdown
                      trigger={
                        <div className="">
                          <CiCircleMore className="text-2xl" />
                        </div>
                      }
                      menu={[
                        {
                          icon: FaRegEdit,
                          title: "Edit",
                          action: () => {
                            navigate(`/stock-history/${stock.uuid}`)
                          },
                        },
                        {
                          icon: CiViewList,
                          title: "View Items",
                          action: () => handleViewItems(stock.uuid),
                        },
                      ]}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </CustomPagination>
      </div>
      <ViewItems isOpen={isOpen} close={() => setIsOpen(false)} orderUuid={orderUuid}/>
    </div>
  );
};

export default StockTransferHistory;
