import Login from "../screens/auth/Login";
import Customers from "../screens/customers";
import Manufacturer from "../screens/customers/Manufacturer";
import Dashboard from "../screens/dashboard";
import Order from "../screens/order";
import Products from "../screens/products";
import AddProducts from "../screens/products/AddProducts";
import Users from "../screens/users";
import ErrorPage from "../screens/error";
import ManageProduct from "../screens/products/ManageProduct";
import Barcode from "../screens/products/Barcode";
import EditProduct from "../screens/products/EditProduct";
import Invoice from "../screens/order/Invoice";
import Branches from "../screens/branches";
import Quotation from "../screens/order/Quotation";
import StockTransfer from "../screens/stock/StockTransfer";
import Settings from "../screens/settings";
import AddPurchase from "../screens/purchase";
import AddStock from "../screens/stock/AddStock";
import ManageCustomer from "../screens/customers/ManageCustomer";
import StockList from "../screens/stock/StockList";
import Shelfs from "../screens/shelf";
import ManageShelf from "../screens/shelf/ManageShelf";
import StockCount from "../screens/stock/StockCount";
import ProductManagement from "../screens/productManagement";
import PurchaseHistory from "../screens/purchase/PurchaseHistory";
import StockTransferHistory from "../screens/stock/StockTransferHistory";
import Permissions from "../screens/permissions";
import ManageEmployee from "../screens/users/ManageEmployee";
import Returns from "../screens/returns";
import SellingGroups from "../screens/products/SellingGroups";
import StockTransferEdit from "../screens/stock/StockTranferEdit";
import ProductsStatistics from "../screens/productStatistics/ProductsStatistics";
import ManagePurchaseHistory from "../screens/purchase/ManagePurchaseHistory";
import Payout from "../screens/payout";
import Cheques from "../screens/account/Cheques";
import ContactUs from "../screens/contact";
import FAQ from "../screens/faq";
import Transactions from "../screens/account/Transactions";
import Wallet from "../screens/account/Wallet";
import IncomeStatement from "../screens/account/IncomeStatement";
import CustomerOverView from "../screens/account/CustomerOverView";
import Expenses from "../screens/account/Expenses";
import Hospital from "../screens/customers/Hospital";
import { AiOutlineBranches } from "react-icons/ai";
import { IoStorefrontSharp } from "react-icons/io5";
import { PiPasswordBold } from "react-icons/pi";
import { GrDocumentPerformance } from "react-icons/gr";
import { GrSecure } from "react-icons/gr";
import { CgProfile } from "react-icons/cg";
import { GrOrganization } from "react-icons/gr";
import Loans from "../screens/account/Loans";
import StockMovement from "../screens/stockMovement";
import { MdOutlineFlightTakeoff } from "react-icons/md";
import { BsDatabaseFillAdd } from "react-icons/bs";
import PurchaseItems from "../screens/purchase/PurchaseItems";
import Return from "../screens/order/Return";
import PurchaseReturns from "../screens/purchase/PurachaseReturns";
import { TbReorder } from "react-icons/tb";
import Attendance from "../screens/attendant";

const openRoutes = [
  {
    route: "/login",
    component: Login,
  },
];

const authRoutes = [
  {
    route: "/dashboard",
    component: Dashboard,
  },
  {
    route: "/products",
    component: Products,
  },
  {
    route: "/products/:id",
    component: ManageProduct,
  },
  {
    route: "/products/edit/:id",
    component: EditProduct,
  },
  {
    route: "/orders",
    component: Order,
  },
  {
    route: "/customers",
    component: Customers,
  },
  {
    route: "/hospital",
    component: Hospital,
  },
  {
    route: "/manufacturer",
    component: Manufacturer,
  },
  {
    route: "/customer/:id/:type?",
    component: ManageCustomer,
  },
  {
    route: "/add-product",
    component: AddProducts,
  },
  {
    route: "/employees",
    component: Users,
  },
  {
    route: "/attendance",
    component: Attendance,
  },
  {
    route: "/employees/:id",
    component: ManageEmployee,
  },
  {
    route: "/payout",
    component: Payout,
  },
  {
    route: "/barcode",
    component: Barcode,
  },
  {
    route: "/stock-count",
    component: StockList,
  },
  {
    route: "/stock-count/:id",
    component: StockCount,
  },
  {
    route: "/purchase-history",
    component: PurchaseHistory,
  },
  {
    route: "/purchase-return",
    component: PurchaseReturns,
  },
  {
    route: "/purchase-history/items/:id",
    component: PurchaseItems,
  },
  {
    route: "/purchase-history/:id",
    component: ManagePurchaseHistory,
  },
  {
    route: "/stock-history",
    component: StockTransferHistory,
  },
  {
    route: "/stock-history/:id",
    component: StockTransferEdit,
  },
  {
    route: "/product-management",
    component: ProductManagement,
  },
  {
    route: "/product-statistics",
    component: ProductsStatistics,
  },
  {
    route: "/stock-movement",
    component: StockMovement,
  },
  {
    route: "/invoice",
    component: Invoice,
  },
  {
    route: "/shelfs",
    component: Shelfs,
  },
  {
    route: "/shelfs/:id",
    component: ManageShelf,
  },
  {
    route: "/add-stocks",
    component: AddStock,
  },
  {
    route: "/add-purchase",
    component: AddPurchase,
  },
  {
    route: "/quotations",
    component: Quotation,
  },
  {
    route: "/returnwithoutid",
    component: Return,
  },
  {
    route: "/stock-transfer",
    component: StockTransfer,
  },
  {
    route: "/settings",
    component: Settings,
  },
  {
    route: "/contact",
    component: ContactUs,
  },
  {
    route: "/faq",
    component: FAQ,
  },
  {
    route: "/permissions",
    component: Permissions,
  },
  {
    route: "/branches",
    component: Branches,
  },
  {
    route: "/selling-groups",
    component: SellingGroups,
  },
  {
    route: "/returns-refunds",
    component: Returns,
  },
  {
    route: "/transactions",
    component: Transactions,
  },
  {
    route: "/wallet",
    component: Wallet,
  },
  {
    route: "/overview",
    component: CustomerOverView,
  },
  {
    route: "/income",
    component: IncomeStatement,
  },
  {
    route: "/expenses",
    component: Expenses,
  },
  {
    route: "/loans",
    component: Loans,
  },
  {
    route: "/cheque",
    component: Cheques,
  },
  {
    route: "*",
    component: ErrorPage,
  },
];

const monthsList = [
  { month: "January", value: 1 },
  { month: "February", value: 2 },
  { month: "March", value: 3 },
  { month: "April", value: 4 },
  { month: "May", value: 5 },
  { month: "June", value: 6 },
  { month: "July", value: 7 },
  { month: "August", value: 8 },
  { month: "September", value: 9 },
  { month: "October", value: 10 },
  { month: "November", value: 11 },
  { month: "December", value: 12 },
];

const numbers = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];

const yearList = [
  "2024",
  "2025",
  "2026",
  "2027",
  "2028",
  "2029",
  "2030",
  "2031",
];

export const kpiValueTypeArr = [
  {
    value: 0,
    name: "Based on Quantity",
  },
  {
    value: 1,
    name: "Based on Percentage",
  },
  {
    value: 2,
    name: "Based on Time",
  },
];

const settingsNav = [
  { name: "Profile", icon: CgProfile, tag: 1 },
  { name: "Company Profile", icon: GrOrganization, tag: 2 },
  { name: "My Branches", icon: AiOutlineBranches, tag: 3 },
  { name: "Store", icon: IoStorefrontSharp, tag: 4 },
  { name: "Reorder", icon: TbReorder, tag: 10 },
  { name: "Reset Pin", icon: PiPasswordBold, tag: 5 },
  { name: "Performance", icon: GrDocumentPerformance, tag: 6 },
  { name: "Permission", icon: GrSecure, tag: 7 },
  { name: "Loan", icon: MdOutlineFlightTakeoff, tag: 8 },
  { name: "Sync Data", icon: BsDatabaseFillAdd, tag: 9 },
];

export { openRoutes, authRoutes, monthsList, yearList, numbers, settingsNav };
