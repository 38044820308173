import { useState } from "react";
import PaginationControl from "../../components/PaginationControl";
import { useLoanRepaymentQuery } from "../../redux/queries/Payments";
import utills from "../../lib/functions";
import { Button } from "flowbite-react";
import moment from "moment";

interface CustomerLoanProps {
  companyId: string;
}

const CustomLoan = ({ companyId }: CustomerLoanProps) => {
  const [filters, setFilters] = useState({
    limit: "0,10",
    company: companyId,
  });

  const { data: loans, isLoading } = useLoanRepaymentQuery(filters);

  return (
    <div>
      <div className="bg-white mt-4 p-2 rounded-lg dark:bg-gray-700 shadow-sm mb-5 flex items-start gap-5 justify-between">
        <div className="bg-gray-100 dark:bg-gray-800 h-18 rounded-lg p-3 flex flex-col justify-between w-48">
          <div className="flex justify-between items-center">
            <span className="text-xs font-semibold text-gray-700 dark:text-white">
              Loan Used
            </span>
          </div>

          <div className="text-gray-700 dark:text-white font-semibold text-xl">
            <span>
              {utills._currency_format(Number(loans?.loan_used ?? 0), "NGN")}
            </span>
          </div>
        </div>

        <Button size="xs" outline>
          Clear Loan
        </Button>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <PaginationControl
          setPage={(limit) => setFilters((prev) => ({ ...prev, limit }))}
          isLoading={isLoading}
          data={loans?.data}
          total={loans?.count ?? 0}
        >
          <table className="w-full text-[10px] text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  #
                </th>
                <th scope="col" className="px-6 py-3">
                  Expected Repayment(₦)
                </th>
                <th scope="col" className="px-6 py-3">
                  Amount Repaid(₦)
                </th>
                <th scope="col" className="px-6 py-3">
                  Default Amount(₦)
                </th>
                <th scope="col" className="px-6 py-3">
                  Date
                </th>
              </tr>
            </thead>
            <tbody>
              {loans?.data?.map((item, i) => (
                <tr
                  key={item.id}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <th scope="col" className="px-6 py-3">
                    {i + 1}
                  </th>
                  <td className="px-6 py-4">
                    {utills._figure_format(Number(item.expected))}
                  </td>
                  <td className="px-6 py-4">
                    {utills._figure_format(Number(item.amount))}
                  </td>
                  <td className="px-6 py-4">
                    {utills._figure_format(Number(item.amount))}
                  </td>
                  <td className="px-6 py-4">
                    {moment(item.crdate).format("LLL")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </PaginationControl>
      </div>
    </div>
  );
};

export default CustomLoan;
