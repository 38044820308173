import React, { useRef, useState, ChangeEvent, useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import {
  Label,
  TextInput,
  Select,
  Textarea,
  Button,
  Tooltip,
  Spinner,
  Checkbox,
} from "flowbite-react";
import { useForm } from "react-hook-form";
import { useGetCategoriesQuery } from "../../redux/queries/settings";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useCreateProductMutation } from "../../redux/queries/products";
import { useAppSelector } from "../../lib/hook";
import toast from "react-hot-toast";
import { FaMinus, FaPlus } from "react-icons/fa";
import { useUploadFileMutation } from "../../redux/queries/upload";
import { GoTrash } from "react-icons/go";
import SellingGroup from "./SellingGroup";
import { CiBarcode } from "react-icons/ci";
import utills from "../../lib/functions";
import { IoIosRemoveCircle } from "react-icons/io";
import ProductSearch from "../../components/ProductSearch";
import ImageView from "../../components/ImageView";
import { set } from "lodash";

interface IFormInput {
  name: string;
  category: string;
  discount?: string;
  price: string;
  selling_price?: string;
  batch_no?: string;
  company: string;
  quantity: string;
  reorder_level?: string;
  reorder_quantity?: string;
  reorder_quantity_limit?: string;
  markup?: string;
  status?: string;
  barcode?: string;
  min?: string;
  max?: string;
  description?: string;
  cashback?: string;
  stock?: string;
}

interface Variant {
  name: string;
  quantity: string;
  is_required: boolean;
}

const schema = yup.object().shape({
  name: yup.string().required("Product name is a required field"),
  category: yup.string().required("Product category is a required field"),
  price: yup.string().required("Cost psrice is a required field"),
  selling_price: yup.string(),
  company: yup.string().required("Company is a required field"),
  batch_no: yup.string(),
  quantity: yup.string().required("Price is a required field"),
  status: yup.string(),
  reorder_level: yup.string(),
  reorder_quantity: yup.string(),
  reorder_quantity_limit: yup.string(),
  markup: yup.string(),
  barcode: yup.string(),
  min: yup.string(),
  max: yup.string(),
  cashback: yup.string(),
  stock: yup.string(),
  description: yup.string(),
});
const AddProducts = () => {
  const { user } = useAppSelector((state) => state.appUserConfig);
  const [createProduct, { isLoading }] = useCreateProductMutation();
  const [imgs, setImgs] = useState<string[]>([]);
  const [imgData, setImgData] = useState<string[]>([]);
  const [flat, setFlat] = useState(0);
  const [variants, setVariants] = useState<Variant[]>([
    { name: "Pack", quantity: "1", is_required: false },
  ]);
  const [sellingGroup, setSellingGroup] = useState<ISellingG[] | []>([]);
  const [crossSelling, setCrossSelling] = useState<Product[]>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });
  const { data: categories } = useGetCategoriesQuery({
    type: user?.company.type,
    status: 1,
  });

  const onSubmit = async (data: IFormInput) => {
    const payload = {
      ...data,
      type: user?.company.type,
      images: "",
      variants: variants,
      flat,
      selling_group: sellingGroup.filter((item) => Number(item.price) > 0),
    };

    if (imgs.length > 0) payload.images = imgData.join(",");

    const item_required = crossSelling.map(x=> x.uuid) 
    const { selling_price, ...rest } = payload;
    const res = (await createProduct({ ...rest, item_required })) as CreatProductResponse;
    if (res?.data?.status !== 200) {
      toast.error(res?.data.eror ?? "Error");
      toast.error(res?.data?.message ?? "Error");
      toast.error(res?.data?.data?.join(",") ?? "Error");
    } else {
      toast.success("Product created successfully");
      reset();
    }
  };
  const formState = watch();

  useEffect(() => {
    if (formState.markup && formState.price && flat === 0) {
      const selling = (Number(formState.markup) / 100) * Number(formState.price);
      setValue("selling_price", (selling + Number(formState.price)).toFixed(0).toString());
    } else {
      const selling = Number(formState.markup) + Number(formState.price);
      setValue("selling_price", selling.toFixed(0).toString());
    }
  }, [formState.markup, formState.price]);

  useEffect(()=> {
    setValue("min", "0")
    setValue("max", "1000")
    setValue("discount", "0")
    setValue("cashback", "0")
    setValue("stock", "0")
    setValue("reorder_level", "1")
    setValue("reorder_quantity", "10")
    setValue("reorder_quantity_limit", "100")
    setValue("quantity", "0")
  },[])

  return (
    <div>
      <Breadcrumb title="Add New Product" />
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="bg-white dark:bg-gray-800 rounded-lg">
            <div className="dark:bg-gray-700 rounded-t-lg p-2">
              <span className="dark:text-white">Enter Product Information</span>
            </div>
            <div className="grid grid-cols-3 gap-4 p-4">
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value="Product Name" />
                </div>
                <TextInput
                  disabled={isLoading}
                  {...register("name")}
                  id="name"
                  type="text"
                  placeholder="Product Name"
                  color={errors?.name ? "failure" : "gray"}
                  helperText={
                    errors.name && (
                      <span className="font-medium text-[10px]"> {errors.name?.message}!</span>
                    )
                  }
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="branch" value="Branch" />
                </div>

                <Select
                  disabled={isLoading}
                  defaultValue={user?.company.uuid}
                  {...register("company")}
                  color={errors?.name ? "failure" : "gray"}
                  helperText={
                    errors.name && (
                      <span className="font-medium text-[10px]"> {errors.name?.message}!</span>
                    )
                  }
                >
                  {user?.branches.map((branch) => (
                    <option key={branch.uuid} value={branch.uuid}>
                      {branch.name}
                      {` ${branch.type !== "branch" ? " (Main branch)" : ""}`}
                    </option>
                  ))}
                </Select>
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="cat" value="Product Category" />
                </div>

                <Select
                  disabled={isLoading}
                  {...register("category")}
                  color={errors?.name ? "failure" : "gray"}
                  helperText={
                    errors.name && (
                      <span className="font-medium text-[10px]"> {errors.name?.message}!</span>
                    )
                  }
                >
                  {categories?.data.map((cat) => (
                    <option value={cat.uuid} key={cat.id}>
                      {cat.name}
                    </option>
                  ))}
                </Select>
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="discount" value="Discount (NGN)" />
                </div>
                <TextInput
                  disabled={isLoading}
                  id="discount"
                  type="number"
                  step="any"
                  placeholder="Discount(NGN)"
                  {...register("discount")}
                  color={errors?.name ? "failure" : "gray"}
                  helperText={
                    errors.name && (
                      <span className="font-medium text-[10px]"> {errors.name?.message}!</span>
                    )
                  }
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="qty" value="Quantity" />
                </div>
                <TextInput
                  disabled={isLoading}
                  {...register("quantity")}
                  id="qty"
                  type="number"
                  step="any"
                  placeholder="Quantity"
                  color={errors?.name ? "failure" : "gray"}
                  helperText={
                    errors.name && (
                      <span className="font-medium text-[10px]"> {errors.name?.message}!</span>
                    )
                  }
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="stu" value="Status" />
                </div>

                <Select id="stu" {...register("status")} disabled={isLoading}>
                  <option value={1}>Active</option>
                  <option value={0}>Inactive</option>
                </Select>
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="markup" value="Unique Markup / Profit" />
                </div>
                <div className="flex items-center w-full">
                  <TextInput
                    className="flex-1"
                    style={{ borderRadius: "7px 0px 0px 7px" }}
                    disabled={isLoading}
                    id="markup"
                    type="number"
                    step="any"
                    placeholder="Unique Markup / Profit"
                    {...register("markup")}
                  />
                  <Select
                    id="prf"
                    onChange={(e) => setFlat(Number(e.target.value))}
                    value={flat}
                    style={{ borderRadius: "0px 7px 7px 0px" }}
                  >
                    <option value={0}>Markup</option>
                    <option value={1}>Profit</option>
                  </Select>
                </div>
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="pricen" value="Cost Price (₦)" />
                </div>
                <TextInput
                  disabled={isLoading}
                  {...register("price")}
                  id="pricen"
                  type="number"
                  step="any"
                  placeholder="Price (₦)"
                  color={errors?.name ? "failure" : "gray"}
                  helperText={
                    errors.name && (
                      <span className="font-medium text-[10px]"> {errors.name?.message}!</span>
                    )
                  }
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="pricen" value="Selling Price (₦)" />
                </div>
                <TextInput
                  disabled
                  {...register("selling_price")}
                  id="pricen"
                  type="number"
                  step="any"
                  placeholder="Price (₦)"
                  color={errors?.name ? "failure" : "gray"}
                  helperText={
                    errors.name && (
                      <span className="font-medium text-[10px]"> {errors.name?.message}!</span>
                    )
                  }
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="min" value="Min" />
                </div>
                <TextInput
                  disabled={isLoading}
                  {...register("min")}
                  id="min"
                  type="number"
                  step="any"
                  placeholder="Min"
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="max" value="Max" />
                </div>
                <TextInput
                  disabled={isLoading}
                  {...register("max")}
                  id="max"
                  type="number"
                  step="any"
                  placeholder="Max"
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="stock" value="Stock Limit" />
                </div>
                <TextInput
                  disabled={isLoading}
                  {...register("stock")}
                  id="stock"
                  type="number"
                  step="any"
                  placeholder="Stock Limit"
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="reward" value="Cash Back" />
                </div>
                <TextInput
                  disabled={isLoading}
                  {...register("cashback")}
                  id="reward"
                  type="number"
                  step="any"
                  placeholder="Cash Back"
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="barcode" value="Barcode" />
                </div>
                <div className="flex items-center w-full">
                  <TextInput
                    disabled={isLoading}
                    {...register("barcode")}
                    id="barcode-input"
                    type="text"
                    className="flex-1"
                    style={{ borderRadius: "7px 0px 0px 7px" }}
                    placeholder="Barcode"
                  />
                  <button
                    type="button"
                    onClick={() => {
                      const code = utills._generate_barcode();
                      setValue("barcode", code.toString());
                    }}
                    className="bg-[#167490] h-[42px] px-2 text-sm text-white font-semibold"
                    style={{ borderRadius: "0px 7px 7px 0px" }}
                  >
                    <CiBarcode size={24} />
                  </button>
                </div>
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="batch_no" value="Batch No" />
                </div>
                <TextInput
                  disabled={isLoading}
                  {...register("batch_no")}
                  id="batch_no"
                  type="text"
                  placeholder="Batch No"
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="reorder" value="Reorder Level" />
                </div>
                <TextInput
                  disabled={isLoading}
                  {...register("reorder_level")}
                  id="reorder"
                  type="text"
                  placeholder="Reorder Level"
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="reorder_q" value="Reorder Qty" />
                </div>
                <TextInput
                  disabled={isLoading}
                  id="reorder_q"
                  type="number"
                  step="any"
                  placeholder="Reorder Qty"
                  {...register("reorder_quantity")}
                />
              </div>

              <div>
                <div className="mb-2 block">
                  <Label htmlFor="reorder_l" value="Reorder Qty Limit" />
                </div>
                <TextInput
                  disabled={isLoading}
                  id="reorder_l"
                  type="number"
                  step="any"
                  placeholder="Reorder Qty Limit"
                  {...register("reorder_quantity_limit")}
                />
              </div>
            </div>
            <div className="p-4 pt-0">
              <div className="">
                <div className="mb-2 block">
                  <Label htmlFor="comment" value="Product Desc" />
                </div>
                <Textarea
                  disabled={isLoading}
                  id="comment"
                  placeholder="Product Desc..."
                  rows={4}
                  {...register("description")}
                />
              </div>

              <div className="mt-5 pt-4 border-t border-gray-300 dark:border-gray-600">
                <div className="dark:text-gray-200 text-sm mb-2">Cross Selling</div>

                <div className={`max-w-[500px] mb-3 relative ${!formState.company && "hidden"}`}>
                  <div className="text-red-500 text-xs font-semibold pl-3">
                    Note: products are from the branch selected.
                  </div>
                  <ProductSearch
                    companyId={formState.company}
                    onSelect={(product) => setCrossSelling((prev) => [...prev, product])}
                  />
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-4 2xl:grid-cols-4 gap-6">
                  {crossSelling.map((product) => {
                     const imgurl =
                     product.images_links[0] ===
                     "https://dev-assets.gohealthy.ng/items/"
                       ? utills._default_img
                       : product.images_links[0];

                       return (
                        <div key={product.uuid} className="flex gap-3 border p-2 relative rounded-md">
                          <button className="text-red-500 absolute -top-3 -right-3" onClick={()=> setCrossSelling((prev) => prev.filter((x) => x.uuid !== product.uuid))}>
                            <IoIosRemoveCircle size={25} />{" "}
                          </button>
                          <ImageView url={imgurl} className="w-10 h-10"   />
                          <div className="font-semibold text-[13px]">{product.name}</div>
                        </div>
                      )
                  })}
                </div>
              </div>

              <div className="mt-8 pt-4 border-t border-gray-300 dark:border-gray-600">
                <SellingGroup
                  removeItem={(uuid) =>
                    setSellingGroup((prev) => prev.filter((x) => x.uuid !== uuid))
                  }
                  updateSellingGroup={(uuid, markup, price) => {
                    const existing = sellingGroup.find((item) => item.uuid === uuid);
                    if (existing) {
                      setSellingGroup((prev) =>
                        prev.map((item) => {
                          if (item.uuid === uuid) {
                            return { uuid, markup, price };
                          } else return item;
                        })
                      );
                    } else setSellingGroup((prev) => [...prev, { uuid, markup, price }]);
                  }}
                  sellinggroup={sellingGroup}
                />
              </div>

              <div className="mt-5 pt-4 border-t border-gray-300 dark:border-gray-600">
                <VarianFields variants={variants} setVariants={setVariants} />
              </div>
              <div className="mt-5">
                <div className="mb-2 block">
                  <Label htmlFor="comment" value="Add Product Image" />
                </div>
                <ImageUpload imgs={imgs} setImgs={setImgs} setImgData={setImgData} />
              </div>
            </div>

            <div className="p-4 w-full">
              <Button isProcessing={isLoading} type="submit" className="w-full">
                Submit
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddProducts;

interface Variant {
  name: string;
  quantity: string;
  is_required: boolean;
}
interface VariantFieldsProps {
  variants: Variant[];
  setVariants: React.Dispatch<React.SetStateAction<Variant[]>>;
}
export const VarianFields: React.FC<VariantFieldsProps> = ({ variants, setVariants }) => {
  const handleAddVariant = () => {
    setVariants([...variants, { name: "", quantity: "", is_required: false }]);
  };

  const handleRemoveVariant = (index: number) => {
    const newVariants = [...variants];
    newVariants.splice(index, 1);
    setVariants(newVariants);
  };

  const handleChange = (
    index: number,
    key: "name" | "quantity" | "is_required",
    value: string | boolean
  ) => {
    if (key === "is_required") {
      setVariants((prev) =>
        prev.map((item, ind) => {
          if (ind === index) {
            return { ...item, is_required: value as boolean };
          } else return item;
        })
      );
    } else {
      // const newVariants = [...variants]
      // newVariants[index][key] = value as string
      // setVariants(newVariants)
      setVariants((prev) =>
        prev.map((item, ind) => {
          if (ind === index) {
            return { ...item, [key]: value as string };
          } else return item;
        })
      );
      // if(key === 'name')inputRef?.current?.focus()
    }
  };

  return (
    <div>
      <span className="dark:text-gray-200 text-sm">Add Variants to product</span>
      {variants.map((variant, index) => {
        const isChecked =
          typeof variant.is_required === "boolean"
            ? variant.is_required
            : variant.is_required === 1;
        return (
          <TextInputComponent
            key={index}
            variant={variant}
            handleChange={handleChange}
            index={index}
            variants={variants}
            isChecked={isChecked}
            handleAddVariant={handleAddVariant}
            handleRemoveVariant={handleRemoveVariant}
          />
        );
      })}
    </div>
  );
};

interface TextFields {
  variant: Variant;
  handleChange: (
    index: number,
    key: "name" | "quantity" | "is_required",
    value: string | boolean
  ) => void;
  index: number;
  variants: Variant[];
  isChecked: boolean;
  handleAddVariant: () => void;
  handleRemoveVariant: (index: number) => void;
}
const TextInputComponent = (props: TextFields) => {
  const {
    variant,
    variants,
    handleChange,
    index,
    isChecked,
    handleAddVariant,
    handleRemoveVariant,
  } = props;
  return (
    <div className="grid grid-cols-3 gap-4 my-4">
      <div>
        <div className="mb-2 block">
          <Label value="Name" />
        </div>
        <TextInput
          type="text"
          placeholder="Name"
          value={variant.name}
          onChange={(e) => handleChange(index, "name", e.target.value)}
        />
      </div>
      <div>
        <div className="mb-2 block">
          <Label value="Quantity" />
        </div>
        <TextInput
          type="number"
          step="any"
          disabled={index === 0}
          placeholder="Quantity"
          value={variant.quantity}
          onChange={(e) => handleChange(index, "quantity", e.target.value)}
        />
      </div>
      <div>
        <Label value="Options" className="opacity-0" />
        <div className="flex items-center gap-3 mt-2">
          <Checkbox
            checked={isChecked}
            onChange={(e) => handleChange(index, "is_required", e.target.checked)}
          />
          <Label>Required</Label>
          {index === variants.length - 1 && (
            <Tooltip content="Add more field">
              <Button className="mt-1" color="gray" onClick={handleAddVariant}>
                <FaPlus />
              </Button>
            </Tooltip>
          )}
          {index !== 0 && (
            <Tooltip content="Delete field">
              <Button className="mt-1" color="gray" onClick={() => handleRemoveVariant(index)}>
                <FaMinus />
              </Button>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export const ImageUpload = ({
  imgs,
  setImgs,
  setImgData,
}: {
  imgs: string[];
  setImgs: React.Dispatch<React.SetStateAction<string[]>>;
  setImgData: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [uploadFile, { isLoading }] = useUploadFileMutation();

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    const res = await uploadFile(file);
    if ("data" in res) {
      const { links, data } = res.data;
      setImgs((prev) => [...prev, ...links]);
      setImgData((prev) => [...prev, ...data]);
    } else toast.error("Unable to upload file");
  };
  return (
    <div className="flex items-center gap-5">
      {imgs.map((link) => (
        <div
          key={link}
          className="w-24 h-24 bg-white rounded-md border border-gray-300 dark:border-gray-600 relative"
          style={{
            backgroundImage: `url(${link})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <span
            onClick={() => setImgs((prev) => prev.filter((x) => x !== link))}
            className="w-5 h-5 rounded-full flex justify-center items-center bg-red-500 hover:bg-red-600 cursor-pointer absolute top-[-5px] left-[-5px]"
          >
            <GoTrash className="text-white text-xs" />
          </span>
        </div>
      ))}
      <div
        onClick={() => inputRef.current?.click()}
        className="w-24 h-24 flex items-center justify-center bg-gray-200 dark:bg-gray-700 cursor-pointer hover:dark:bg-gray-600 hover:bg-gray-300 rounded-md"
      >
        {isLoading ? <Spinner /> : <FaPlus className="text-2xl text-gray-500 dark:text-white" />}
      </div>
      <input
        disabled={isLoading}
        ref={inputRef}
        onChange={handleFileUpload}
        className="hidden"
        type="file"
      />
    </div>
  );
};
