import { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { Button, Spinner, Tooltip, Banner, TextInput } from "flowbite-react";
import { IoIosAdd } from "react-icons/io";
import {
  useDeleteOrderMutation,
  useLazyGetOrderQuery,
  useLazyGetOrdersQuery,
} from "../../redux/queries/order";
import { useGetSettingsQuery } from "../../redux/queries/settings";
import { useAppSelector } from "../../lib/hook";
import { FaFileInvoice, FaSearch, FaShoppingBasket } from "react-icons/fa";
import utills from "../../lib/functions";
import moment from "moment";
import { GoTrash } from "react-icons/go";
import { BsBlockquoteLeft } from "react-icons/bs";
import toast from "react-hot-toast";
import BranchSwitcher from "../../components/BranchSwitcher";
import { useNavigate } from "react-router-dom";
import OrderDataScreen from "./OrderDataScreen";
import { MdOutlineLockReset } from "react-icons/md";
import CustomPagination from "../../components/CustomPagination";

interface IPayload {
  limit: string;
  company: string;
  type: number;
  paid: number;
  invoice?: number;
  id?: string;
}
const Order = () => {
  const navigate = useNavigate();
  const { defaultBranchUUid } = useAppSelector((state) => state.appUserConfig);
  const [filters, setFilters] = useState({
    limit: "0,10",
    company: defaultBranchUUid,
    type: 10,
    paid: 0,
    id: "",
  });
  const [screen, setScreen] = useState<string>("quotation");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [oLoading, setOLoading] = useState<boolean>(false);
  const [getOrder, { data: order }] = useLazyGetOrderQuery();

  const [getAllOrders, { data: orders, isLoading: orderLoading }] =
    useLazyGetOrdersQuery();
  console.log(orders);
  const { data: settings } = useGetSettingsQuery();
  //console.log('settings', settings)

  const order_status = settings?.data.order_status ?? [];

  const onPageChange = async (limit: string) => {
    const res = await getAllOrders({
      ...filters,
      limit,
      type: screen === "quotation" ? 10 : screen === "invoice" ? 11 : 12,
      paid: screen === "orders" ? 1 : 0,
      verified: screen === "orders" ? 1 : 0,
      invoice: screen === "invoice" ? 1 : 0,
      order: screen === "orders" ? 1 : 0,
    });
    if ("data" in res) {
      if ((res.data?.count as number) > 0) {
        if (res.data?.data) {
          const [firstOrder] = res.data.data;
          getOrder(firstOrder.uuid);
        }
      }
    }
  };

  const getNeededOrders = async (
    screen: string,
    type?: number,
    selectFirst?: boolean,
    searchId?: string
  ) => {
    setScreen(screen);
    setIsLoading(true);
    const payload: IPayload = { ...filters };
    if (searchId) payload.id = searchId;
    if (screen === "invoive") payload.invoice = 1;
    const res = await getAllOrders({
      ...payload,
      type: type ?? filters.type,
      paid: screen === "orders" ? 1 : 0,
      verified: screen === "orders" ? 1 : 0,
      invoice: screen === "invoice" ? 1 : 0,
      order: screen === "orders" ? 1 : 0,
    });
    if ("data" in res && selectFirst) {
      if ((res.data?.count as number) > 0) {
        if (res.data?.data) {
          const [firstOrder] = res.data.data;
          getOrder(firstOrder.uuid);
        }
      }
    }
    setIsLoading(false);
  };

  const fetchOrder = async (id: string) => {
    setOLoading(true);
    await getOrder(id);
    setOLoading(false);
  };

  useEffect(() => {
    getNeededOrders(screen, undefined, true);
  }, [filters.company]);

  return (
    <div className="relative">
      {isLoading && (
        <div className="absolute w-full h-full top-0 left-0 bg-gray-400/30 dark:bg-black/10 z-30 pt-6 flex justify-center gap-3">
          <Spinner />
          <Spinner />
          <Spinner />
        </div>
      )}
      <Breadcrumb
        title="Order List"
        rightButton={
          <Button size="xs" onClick={() => navigate("/pos")}>
            <IoIosAdd className="mr-2 h-5 w-5" /> New Order
          </Button>
        }
      />

      <div className="mt-10">
        <Banner className="mb-5">
          <div className="flex w-full flex-col justify-between border-b border-gray-200 bg-gray-50 p-4 dark:border-gray-600 dark:bg-gray-700 md:flex-row">
            <div className="mb-4 md:mb-0 md:mr-4">
              <h2 className="mb-1 text-base font-semibold text-gray-900 dark:text-white">
                List Of All Orders.
              </h2>
              <p className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                Manage all orders from customers and check status of all orders
                in real time.
              </p>
            </div>
            <div className="flex flex-shrink-0 items-center">
              <FaShoppingBasket className="text-5xl text-gray-500 dark:text-white" />
            </div>
          </div>
        </Banner>
      </div>

      <div className="bg-white p-4 rounded-lg dark:bg-gray-700 shadow-sm mb-4">
        <div className="grid grid-cols-4 gap-3">
          <div>
            <BranchSwitcher
              sizing="sm"
              onChange={(value) =>
                setFilters((prev) => ({ ...prev, company: value }))
              }
            />
          </div>
          <div>
            <TextInput
              sizing="sm"
              id="idsearch"
              type="number"
              step="any"
              placeholder="Search by id"
            />
          </div>
          <div>
            <button
              onClick={() => {
                const el = document.getElementById(
                  "idsearch"
                ) as HTMLInputElement;
                getNeededOrders(screen, undefined, true, el.value);
              }}
              type="button"
              className="p-2.5 ms-2 text-sm font-medium text-white bg-[#167490] rounded-lg border border-[#167490] hover:bg-[#167490]/80 focus:ring-4 focus:outline-none"
            >
              <FaSearch />
              <span className="sr-only">Search</span>
            </button>
            <button
              onClick={() => {
                const el = document.getElementById(
                  "idsearch"
                ) as HTMLInputElement;
                el.value = "";
                setFilters((prev) => ({ ...prev, id: "" }));
                getNeededOrders(screen, undefined, true);
              }}
              type="button"
              className="p-2.5 ms-2 text-sm font-medium text-[#167490] bg-transparent rounded-lg border border-[#167490] hover:bg-[#167490]/80 hover:text-white"
            >
              <MdOutlineLockReset />
              <span className="sr-only">Reset</span>
            </button>
          </div>
        </div>
      </div>

      <div className="mb-4 p-3 rounded-sm bg-white dark:bg-gray-700 flex items-center gap-3">
        <Button
          outline={screen !== "quotation"}
          className="rounded-sm _remove_radius"
          size="xs"
          onClick={() => {
            getNeededOrders("quotation", 10, true);
          }}
        >
          {" "}
          <BsBlockquoteLeft className="mr-2 h-5 w-5" />
          Quotation
        </Button>
        <Button
          outline={screen !== "invoice"}
          className="rounded-sm _remove_radius"
          size="xs"
          onClick={() => {
            getNeededOrders("invoice", 11, true);
          }}
        >
          {" "}
          <FaFileInvoice className="mr-2 h-5 w-5" />
          Invoice
        </Button>
        <Button
          onClick={() => {
            getNeededOrders("orders", 12, true);
          }}
          outline={screen !== "orders"}
          className="rounded-sm _remove_radius"
          size="xs"
        >
          {" "}
          <FaFileInvoice className="mr-2 h-5 w-5" />
          Orders
        </Button>
      </div>

      <CustomPagination
        isLoading={orderLoading}
        setPage={(limit) => onPageChange(limit)}
        total={orders?.count ?? 0}
        data={orders?.data ?? []}
      >
        <div className="flex w-full">
          <aside className="w-80">
            {orders?.data.map((item) => {
              return (
                <OrderCard
                  key={item.uuid}
                  item={item}
                  order_status={order_status}
                  getOrder={fetchOrder}
                  orderId={order?.data.id}
                />
              );
            })}
          </aside>

          <OrderDataScreen
            getOrder={getOrder}
            screen={screen}
            order={order?.data}
            isLoading={oLoading}
            orderLoading={orderLoading}
          />
        </div>
      </CustomPagination>
    </div>
  );
};

export default Order;

interface OrderCardCom {
  item: Order;
  order_status: OrderStatus[] | [];
  getOrder: (id: string) => Promise<void>;
  orderId?: number;
}
const OrderCard = (props: OrderCardCom) => {
  const { item, order_status, getOrder, orderId } = props;
  const [orderLoading, setOrderLoading] = useState<boolean>(false);
  const [deleteOrder] = useDeleteOrderMutation();

  const getOrderData = async (id: string) => {
    setOrderLoading(true);
    await getOrder(id);
    setOrderLoading(false);
  };

  const handleDelete = async (id: string) => {
    const action = window.confirm("Are you sure you want to delete");
    if (action) {
      const res = await deleteOrder(id);
      if ("data" in res) {
        if (res.data.status !== 200)
          toast.error(res.data.message || res.data.error);
        else toast.success("Record Deleted");
      } else toast.error("Unable to delete product");
    }
  };
  //console.log(item)
  return (
    <div
      key={item.id}
      onClick={() => getOrderData(item.uuid)}
      className={`bg-white cursor-pointer relative dark:bg-gray-800 shadow-sm outline-green-400 rounded-sm p-2 text-xs mb-3 ${
        orderId === item.id && "outline outline-2"
      }`}
    >
      {orderLoading && (
        <div className="absolute w-full bg-black/20 dark:bg-gray-400/20 h-full z-20 top-0 left-0 flex items-center justify-center">
          <Spinner />
        </div>
      )}
      <div className="flex justify-between items-center mb-2">
        <span className="text-gray-700 font-semibold dark:text-white">
          #{item.id}
        </span>
        <span className="text-[#167490] font-bold">
          {utills._currency_format(item.total_price, "NGN")}
        </span>
      </div>

      <ul className="divide-y divide-gray-200 dark:divide-gray-500">
        <li className="py-1">
          <div className="flex items-center space-x-2">
            <div className="min-w-0 flex-1">
              <p className="truncate font-medium text-gray-900 dark:text-white">
                Total Items
              </p>
            </div>
            <div className="inline-flex items-center font-semibold text-gray-900 dark:text-white">
              {item.items}
            </div>
          </div>
        </li>

        <li className="py-1">
          <div className="flex items-center space-x-2">
            <div className="min-w-0 flex-1">
              <p className="truncate font-medium text-gray-900 dark:text-white">
                Accept Status
              </p>
            </div>
            <div className="inline-flex items-center font-semibold text-gray-900 dark:text-white">
              <div
                className={`h-2.5 w-2.5 rounded-full ${
                  item.accept === 1 ? "bg-green-500" : "bg-red-500"
                } me-2`}
              ></div>
              {item.accept === 1 ? "Accepted" : "Pending"}
            </div>
          </div>
        </li>
        <li className="py-1">
          <div className="flex items-center space-x-2">
            <div className="min-w-0 flex-1">
              <p className="truncate font-medium text-gray-900 dark:text-white">
                Order Status
              </p>
            </div>
            <div className="inline-flex items-center font-semibold text-gray-900 dark:text-white">
              {utills._get_status_value(item.status, order_status)}
            </div>
          </div>
        </li>

        <li className="py-1">
          <div className="flex items-center space-x-2">
            <div className="min-w-0 flex-1">
              <p className="truncate font-medium text-gray-900 dark:text-white">
                Delivery Fee
              </p>
            </div>
            <div className="inline-flex items-center font-semibold text-gray-900 dark:text-white">
              {utills._currency_format(item.delivery_price, "NGN")}
            </div>
          </div>
        </li>

        <li className="py-1">
          <div className="flex items-center space-x-2">
            <div className="min-w-0 flex-1">
              <p className="truncate font-medium text-gray-900 dark:text-white">
                Order Type
              </p>
            </div>
            <div className="inline-flex items-center font-semibold text-gray-900 dark:text-white">
              {item.order.order_type}
            </div>
          </div>
        </li>

        <li className="py-1">
          <div className="flex items-center space-x-2">
            <div className="min-w-0 flex-1">
              <p className="truncate font-medium text-gray-900 dark:text-white">
                Created
              </p>
            </div>
            <div className="inline-flex items-center font-semibold text-gray-900 dark:text-white">
              {moment(item.created_at).fromNow()}
            </div>
          </div>
        </li>
      </ul>
      {orderId === item.id && (
        <div className="flex justify-between items-center mt-3">
          <span className="dark:text-gray-300 text-gray-800"></span>
          <Tooltip content="Delete Order">
            <span
              onClick={(e) => {
                e.stopPropagation();
                handleDelete(item.uuid);
              }}
              className="text-red-600"
            >
              <GoTrash />
            </span>
          </Tooltip>
        </div>
      )}
    </div>
  );
};
